import type { HubResponseProps } from '@readme/iso';

import React from 'react';

import Discussions from '@routes/Discuss';
import SectionGate from '@routes/SuperHub/components/PlanAccess/SectionGate';

export default function SuperHubDiscussions(props: HubResponseProps) {
  return (
    <SectionGate section="discuss">
      <Discussions {...props} />
    </SectionGate>
  );
}
