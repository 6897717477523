import Oas from 'oas';
import React from 'react';

import { useAPIDesignerStore } from '@core/store';

import APISectionHeader from '@ui/API/SectionHeader';
import Flex from '@ui/Flex';
import Toggle from '@ui/Toggle';

import classes from './index.module.scss';

interface SecuritySchemePickerProps {
  disabled: boolean;
}

const SecuritySchemePicker: React.FC<SecuritySchemePickerProps> = ({ disabled }) => {
  const [path, method, schema, getAvailableSecuritySchemes, toggleSecurityScheme] = useAPIDesignerStore(s => [
    s.apiObject?.path,
    s.apiObject?.method,
    s.apiObject?.schema,
    s.getAvailableSecuritySchemes,
    s.toggleSecurityScheme,
  ]);

  if (!path || !method) {
    return null;
  }

  const oas = new Oas(schema);
  const operation = oas.operation(path, method);

  const availableSchemes = getAvailableSecuritySchemes();
  const operationSchemes = operation?.getSecurity();

  // We don't yet support openIdConnect or oauth2 auth types
  const hasUnsupportedAuthType = Object.values(availableSchemes).some(
    s => s.type === 'openIdConnect' || s.type === 'oauth2',
  );
  // We don't yet support multiple required auth for an endpoint (ex: both apiKey and bearer being required)
  const hasComplexAuth = operationSchemes.some(s => Object.keys(s).length > 1);

  const shouldBeDisabled = hasUnsupportedAuthType || hasComplexAuth;

  // Checks if a specific scheme is used by this endpoint
  const operationHasScheme = (scheme: string) => {
    return operationSchemes.some(s => {
      return Object.keys(s).length === 1 && Object.keys(s)[0] === scheme;
    });
  };

  // Nothing for them to select, so hide the component
  if (!Object.keys(availableSchemes).length) {
    return null;
  }

  return (
    <Flex align="stretch" gap="0" layout="col">
      <APISectionHeader heading="CREDENTIALS" />
      <Flex align="stretch" className={classes.SecuritySchemePicker} gap="xs" layout="col">
        {shouldBeDisabled ? (
          <div className={classes['SecuritySchemePickerItem-info']}>
            This API definition has security scheme definitions that are not currently supported by the API Designer. It
            should be edited directly in your synced file.
          </div>
        ) : (
          <>
            {Object.keys(availableSchemes).map(scheme => (
              <Flex key={scheme} align="center" className={classes.SecuritySchemePickerItem}>
                <label htmlFor={`${scheme}-toggle`}>{scheme}</label>
                <Toggle
                  checked={operationHasScheme(scheme)}
                  disabled={disabled}
                  id={`${scheme}-toggle`}
                  onChange={() => toggleSecurityScheme(scheme)}
                  size="sm"
                  type="toggle"
                />
              </Flex>
            ))}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default SecuritySchemePicker;
