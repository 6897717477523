import React, { useEffect } from 'react';

import { useProjectStore, useSuperHubStore } from '@core/store';

import PageError from '../PageError';

interface Props {
  errorType?: 'migration' | 'save';
  message?: string | null;
  projectId: string | null;
  projectName: string;
  slug?: string | null;
  title?: string | null;
}

export const ValidationError = ({ errorType, title, projectName, message, slug, projectId }: Props) => {
  const errorDisplayRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorType && ['save', 'migration'].includes(errorType)) {
      errorDisplayRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errorType]);

  return errorType === 'save' ? (
    <PageError
      ref={errorDisplayRef}
      mailtoAddress="support+mdx@readme.com"
      mailtoSubject="MDX Validation Issue"
      message={message}
      projectId={projectId}
      projectName={projectName}
      slug={slug}
      subtitle="We found an error when attempting to save your document. Please correct the following issue and try again:"
      title="Validation Error"
    />
  ) : errorType === 'migration' ? (
    <PageError
      ref={errorDisplayRef}
      mailtoBody={`We're having trouble fixing the page "${title}" after attempting to migrate the project "${projectName}" to MDX.`}
      mailtoSubject="MDX Migration Issue"
      message={message}
      projectId={projectId}
      projectName={projectName}
      slug={slug}
      subtitle="We found an error trying to render your document. Please correct the following issue:"
      title="Invalid MDX"
    />
  ) : null;
};

export default function ValidationErrorConnected() {
  const [projectName, projectId] = useProjectStore(s => [s.data.name, s.data.id]);
  const [renderable, mdxValidationError, title, slug] = useSuperHubStore(s => [
    (s.document.getGuidesPageData() || s.document.getReferencePageData())?.renderable,
    s.document.mdxValidationError,
    s.document.data?.title,
    s.document.data?.slug,
  ]);

  return (
    <ValidationError
      errorType={mdxValidationError ? 'save' : renderable?.status === false ? 'migration' : undefined}
      message={mdxValidationError || renderable?.message}
      projectId={projectId}
      projectName={projectName}
      slug={slug}
      title={title}
    />
  );
}
