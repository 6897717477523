import { useState, useEffect, useMemo } from 'react';

import useDragAndDropContext from '@core/hooks/useDragAndDropContext';

import type { ItemDragType, ItemDragObject } from '@ui/Dash/PageNav/DragDrop';

/**
 * Subscribes to our `DragDropMonitor` and returns the `id` of the category or
 * page that is currently dragging. Only one item may be dragging at any given
 * time. When none is dragging, returns `undefined` in place of the `id`.
 */
export default function useDraggingMonitor() {
  const { monitor } = useDragAndDropContext();
  const [draggingCategory, setDraggingCategory] = useState<string>();
  const [draggingPage, setDraggingPage] = useState<string>();

  useEffect(() => {
    const unsubscribe = monitor?.subscribeToStateChange(() => {
      const itemType = monitor.getItemType() as ItemDragType;
      const item = monitor.getItem() as ItemDragObject;

      // Debouncing the state update here until the next animation frame fixes a
      // bug that causes the drag operation to immediately end after picking up
      // a category. This is most likely a result of the virtual list updating
      // in parallel with the drag-related update. Delaying the drag update
      // fixes the issue.
      window.requestAnimationFrame(() => {
        setDraggingCategory(itemType === 'category' ? item.id : undefined);
        setDraggingPage(itemType === 'item' ? item.id : undefined);
      });
    });
    return () => unsubscribe?.();
  }, [monitor]);

  return useMemo(
    () => ({
      /** Contains the category ID that is currently dragging. */
      draggingCategory,
      /** Contains the page ID that is currently dragging. */
      draggingPage,
    }),
    [draggingCategory, draggingPage],
  );
}
