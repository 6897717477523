import type { TreeNodeCategory } from './types';

import React, { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';

import { useSuperHubContext } from '@routes/SuperHub/components/Context';
import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import type { PageNavCategoryProps } from '@ui/Dash/PageNav';
import { PageNavCategory, getPageNavItemElementId } from '@ui/Dash/PageNav';

import { useConfirmDeletionContext } from '../ConfirmDeletion';
import { useDisabledStateContext } from '../DisabledStateContext';

/**
 * Top-level category title for custom pages.
 */
export const customPageCategory = 'Custom Pages';

/**
 * Top-level category title for changelogs.
 */
export const changelogCategory = 'Changelogs';

export interface SidebarNavCategoryProps {
  node: TreeNodeCategory;
}

/**
 * Overrides the default context actions for the sidebar category.
 */
const configureActions: PageNavCategoryProps['configureActions'] = {
  'move-toggle': { hidden: true },
};

export const SidebarNavCategory = React.memo(function SidebarNavCategory({ node }: SidebarNavCategoryProps) {
  const category = node.data;
  const { routeParentCategory } = useParams<SuperHubRouteParams>();
  const { browserRouterHistory } = useSuperHubContext();
  const [categories, isCreateNewPage, renameCategory, reorderCategory, routeSection] = useSuperHubStore(s => [
    s.sidebar.data,
    s.editor.isCreateNewPage,
    s.sidebar.renameCategory,
    s.sidebar.reorderCategory,
    s.routeSection,
  ]);
  const { setPendingDelete } = useConfirmDeletionContext();
  const isSidebarDisabled = useDisabledStateContext();
  const [editable, setEditable] = useState(false);

  const hasNewPage =
    isCreateNewPage && [routeParentCategory, customPageCategory, changelogCategory].includes(category.title);

  const handleAction = useCallback<NonNullable<PageNavCategoryProps['onAction']>>(
    ({ action }) => {
      switch (action) {
        case 'rename':
          setEditable(true);
          break;
        case 'delete':
          setPendingDelete(category);
          break;
        case 'add':
          browserRouterHistory.push(`/create/${routeSection}/${category.title}`);
          break;
        default:
          // eslint-disable-next-line no-console
          console.warn(`Unhandled action: "${action}"`);
          break;
      }
    },
    [browserRouterHistory, category, routeSection, setPendingDelete],
  );

  const handleEdit = useCallback<NonNullable<PageNavCategoryProps['onEdit']>>(
    ({ label: newTitle, isValid }) => {
      // The esc button should exit editing mode WITHOUT submitting. Verify
      // title isn't empty and has changed. Otherwise exit without saving.
      if (!isValid) {
        setEditable(false);
        return undefined;
      }

      // Validate category name to prevent duplicate titles.
      const isDuplicateTitle = categories.some(c => c.title === newTitle);
      if (isDuplicateTitle) {
        return 'Category title is already taken.';
      }

      setEditable(false);
      renameCategory(category, newTitle);
      return undefined;
    },
    [categories, category, renameCategory],
  );

  const handleMove = useCallback<NonNullable<PageNavCategoryProps['onMove']>>(
    ({ source, target }) => {
      reorderCategory(source.id, target.position);
    },
    [reorderCategory],
  );

  const handleToggle = useCallback<NonNullable<PageNavCategoryProps['onToggle']>>(() => {
    node.toggleOpen();
  }, [node]);

  /**
   * Whether or not the category should be collapsible or have actions.
   * This is determined by the route section and the category title
   */
  const isLimitedCategory = useMemo(() => {
    const isChangelogCategory = routeSection === 'changelog' && category.title === changelogCategory;
    const isCustomPageCategory = routeSection === 'page' && category.title === customPageCategory;

    return isChangelogCategory || isCustomPageCategory;
  }, [category.title, routeSection]);

  return (
    <PageNavCategory
      aria-owns={category.pages?.map(page => getPageNavItemElementId(page)).join(' ') || undefined}
      aria-posinset={node.position + 1}
      aria-setsize={node.positionLength}
      collapsible={!isLimitedCategory}
      configureActions={configureActions}
      disabled={isSidebarDisabled}
      editable={editable}
      id={category.uri}
      label={category.title}
      onAction={handleAction}
      onEdit={handleEdit}
      onMove={handleMove}
      onToggle={handleToggle}
      position={node.position}
      showActions={!isLimitedCategory}
      startOpened={node.isOpen}
      totalChildPages={(category.pages?.length ?? 0) + (hasNewPage ? 1 : 0)}
    />
  );
});
