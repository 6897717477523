import { getSupportedLanguages } from '@readme/oas-to-snippet/languages';
import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useAPIDesignerStore } from '@core/store';

import Button from '@ui/Button';
import CodeSnippet from '@ui/CodeSnippet';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import LanguageIcon from '../../LanguagePicker/LanguageIcon';
import APIDesignerTab from '../components/APIDesignerTab';

import classes from './style.module.scss';

const initialStateLanguageIcons = ['node', 'ruby', 'javascript', 'python', 'php', 'swift', 'java', 'http'];

interface RequestExampleEditorProps {
  /** Whether the editor should be disabled (i.e. if endpoint has limited functionality) */
  disabled?: boolean;
}

const RequestExampleEditor = ({ disabled }: RequestExampleEditorProps) => {
  const bem = useClassy(classes, 'RequestExampleEditor');

  const [addNewSnippet, codeSamples, editorState, hasExamples, selectedExample, updateSnippet] = useAPIDesignerStore(
    s => [
      s.requestExampleEditor.addCustomRequestExample,
      s.requestExampleEditor.codeSamples,
      s.requestExampleEditor.editorState,
      s.requestExampleEditor.hasExamples,
      s.requestExampleEditor.selectedExample,
      s.requestExampleEditor.updateSnippet,
    ],
  );

  const languages = getSupportedLanguages();

  return (
    <Flex
      align="stretch"
      className={bem('&', disabled && '_disabled')}
      gap="0"
      inert={disabled ? '' : undefined}
      justify="start"
      layout="col"
      tag="section"
    >
      {editorState === 'initial' && (
        <Flex className={bem('-initial-state')}>
          <div className={bem('-initial-state-header')}>REQUEST CODE</div>
          <div className={bem('-initial-state-icon-container')}>
            <div className={bem('-initial-state-icon-label')}>
              Dynamic code samples are automatically generated for over 20 languages and frameworks.
            </div>
            <Flex align="center" className={bem('-initial-state-icon-list')} gap="md" justify="center" wrap="wrap">
              {initialStateLanguageIcons.map((languageName, i) => (
                <LanguageIcon key={i} className={bem('-initial-state-icon')} language={languageName} />
              ))}
            </Flex>
          </div>
          <div className={bem('-initial-state-divider')} />
          <Button
            className={bem('-initial-state-button')}
            ghost
            kind="contrast"
            onClick={() => addNewSnippet()}
            size="xs"
            uppercase={false}
          >
            <Icon name="edit-2" />
            Write your own static samples
          </Button>
        </Flex>
      )}

      {editorState === 'custom' && !!hasExamples && (
        <>
          <Flex align="center" className={bem('-header')} gap="0" justify="start" tag="header">
            <Flex className={bem('-header-tabs')} gap="0" justify="start">
              {!!codeSamples &&
                codeSamples!.map((sample, i) => (
                  <APIDesignerTab
                    key={i}
                    example={{
                      index: i,
                      languages,
                      sample,
                      selectedExampleType: 'request',
                    }}
                  />
                ))}
            </Flex>
            <button className={bem('-header-add')} onClick={() => addNewSnippet()} type="button">
              <Icon color="gray70" name="plus" />
            </button>
          </Flex>
          <CodeSnippet
            className={bem('-code')}
            code={codeSamples![selectedExample]?.code || ''}
            editorProps={{ onChange: updateSnippet }}
            language={codeSamples![selectedExample]?.language || ''}
            options={{
              dark: true,
              foldGutter: true,
              highlightMode: true,
              inputStyle: 'contenteditable' as const,
            }}
          />
        </>
      )}
    </Flex>
  );
};

export default RequestExampleEditor;
