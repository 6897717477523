import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { useSuperHubStore, type SuperHubDocumentData } from '@core/store';

import { EditorKeyContextProvider } from './EditorKeyContext';
import { EditorValueProxyContextProvider } from './EditorValueProxyContext';
import { FormConfirmationContextProvider } from './FormConfirmationContext';

/**
 * Provides a shared react-hook-form context for the SuperHub editor form.
 */
export default function SuperHubEditorFormContext({ children }) {
  const [documentData] = useSuperHubStore(s => [s.document.data]);

  // Use store document as defaults when creating or editing pages.
  const defaultValues = useMemo(() => {
    return (documentData || {}) as SuperHubDocumentData;
  }, [documentData]);

  const formValue = useForm<SuperHubDocumentData>({ defaultValues });

  useEffect(() => {
    formValue.reset(defaultValues);
  }, [defaultValues, formValue]);

  return (
    <FormProvider {...formValue}>
      <EditorKeyContextProvider>
        <EditorValueProxyContextProvider>
          <FormConfirmationContextProvider>{children}</FormConfirmationContextProvider>
        </EditorValueProxyContextProvider>
      </EditorKeyContextProvider>
    </FormProvider>
  );
}

export const useSuperHubEditorFormContext = () => useFormContext<SuperHubDocumentData>();
export * from './EditorValueProxyContext';
export * from './FormConfirmationContext';
export * from './EditorKeyContext';
