import type { APIDefinitionsReadType, ReadAPIDefinitionType } from '@readme/api/src/mappings/apis/types';

import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import { useReadmeApiNext } from '@core/hooks/useReadmeApi';
import useUniqueId from '@core/hooks/useUniqueId';
import { useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Input from '@ui/Input';
import Radio from '@ui/Radio';
import RadioGroup from '@ui/RadioGroup';
import { RHFGroup } from '@ui/RHF';
import Select from '@ui/Select';
import Spinner from '@ui/Spinner';

import { defaultSchema } from './defaults';
import classes from './index.module.scss';
import useAuthenticationTypeFields from './useAuthenticationTypeFields';

interface ApiDefinitionCreateOrUpdateProps {
  definition?: APIDefinitionsReadType | null;
  onCancel: () => void;
  onSave: () => void;
}

/**
 * Renders a form to create or update an API definition for the API Designer.
 */
export default function ApiDefinitionCreateOrUpdate({
  definition,
  onCancel,
  onSave,
}: ApiDefinitionCreateOrUpdateProps) {
  const uid = useUniqueId('ApiDefinitionCreateOrUpdate');
  const bem = useClassy(classes, 'ApiDefinitionCreateOrUpdate');

  const [createDefinition, updateDefinition] = useSuperHubStore(s => [
    s.apiDefinitions.createDefinition,
    s.apiDefinitions.updateDefinition,
  ]);

  const isCreate = !definition;
  // Fetch the OAS schema if we're updating an existing definition.
  const { data: definitionData, isLoading } = useReadmeApiNext<ReadAPIDefinitionType>(
    !isCreate ? definition.uri : null,
  );

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
    setError,
  } = useForm({
    // If we're creating a new definition, use the default schema.
    // If we're updating an existing definition, use the schema from the API.
    defaultValues: isCreate ? defaultSchema : definitionData?.data?.schema || {},
  });

  useEffect(() => {
    if (definitionData) {
      reset(definitionData.data.schema);
    }
  }, [definitionData, reset]);

  const onSubmit = handleSubmit(async data => {
    try {
      if (isCreate) {
        await createDefinition({ schema: data, upload_source: 'apidesigner' });
      } else {
        await updateDefinition(definition.filename, { schema: data, upload_source: 'apidesigner' });
      }
      onSave?.();
    } catch (error) {
      if (error.status === 409 && error.data.title.includes('An API with this name already exists.')) {
        // This error message from the server is more API specific than we want to render in the UI.
        setError('info.title', { message: 'An API with this name already exists.' });
      } else {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  });

  const securitySchemes = useWatch({ control, name: 'components.securitySchemes' });

  // The values for the authentication type fields are dynamic objects that can't be assigned to form input `value`
  // attributes. So the `useAuthenticationTypeFields` hook generates the field value getters and active state for
  // each auth type.
  const { securitySchemesFields, isSecuritySchemesDisabled, currentSecurityScheme } =
    useAuthenticationTypeFields(securitySchemes);

  return isLoading ? (
    <Spinner className={bem('-spinner')} />
  ) : (
    <form onSubmit={onSubmit}>
      <RHFGroup control={control} id={uid('api-title')} label="API Title" name="info.title" required>
        {({ field }) => <Input {...field} autoComplete="off" size="sm" />}
      </RHFGroup>
      <RHFGroup
        control={control}
        description={
          <span>
            Indicate variables within curly brackets <code className={bem('-code')}>&#123; &#125;</code>
          </span>
        }
        id={uid('target-host-url')}
        isUrl
        label="Target Host URL"
        name="servers.0.url"
        required
      >
        {({ field }) => <Input {...field} autoComplete="off" size="sm" />}
      </RHFGroup>
      <RHFGroup
        control={control}
        label="Authentication Type"
        name="components.securitySchemes"
        warningMessage={
          isSecuritySchemesDisabled
            ? 'Multiple security schemes were detected in your OAS file, which the API Designer does not support. Edit your OAS file directly to update them.'
            : null
        }
      >
        {({ field }) => {
          return (
            <RadioGroup disabled={isSecuritySchemesDisabled} fullWidth>
              {securitySchemesFields.map(({ label, checked, getValue }) => (
                <Radio
                  key={label}
                  {...field}
                  checked={checked}
                  label={label}
                  onChange={() => field.onChange(getValue())}
                />
              ))}
            </RadioGroup>
          );
        }}
      </RHFGroup>

      {!!currentSecurityScheme.isApiKey && (
        <FormGroup label="API Key">
          <div className={bem('-apikeys')}>
            <div className={bem('-apikeys-header')}>
              <span className={bem('-apikeys-header-label')}>In</span>
              <span className={bem('-apikeys-header-label')}>Name</span>
            </div>
            <div className={bem('-apikeys-row')}>
              <RHFGroup
                control={control}
                errorStyle="tooltip"
                id={uid('api-key-name')}
                name={`components.securitySchemes.${currentSecurityScheme.key}.in`}
                required
                size="sm"
              >
                {({ field }) => (
                  <Select
                    className={bem('-apikeys-row-input')}
                    options={[
                      {
                        label: 'Header',
                        value: 'header',
                      },
                      {
                        label: 'Query',
                        value: 'query',
                      },
                    ]}
                    size="xs"
                    {...field}
                  />
                )}
              </RHFGroup>
              <RHFGroup
                control={control}
                errorStyle="tooltip"
                id={uid('api-key-name')}
                name={`components.securitySchemes.${currentSecurityScheme.key}.name`}
                required
                size="sm"
              >
                {({ field }) => (
                  <Input className={bem('-apikeys-row-input')} placeholder="Value" size="xs" {...field} />
                )}
              </RHFGroup>
            </div>
          </div>
        </FormGroup>
      )}

      <Flex align="center" className={bem('-form-footer')}>
        <Button
          kind="secondary"
          onClick={() => {
            reset();
            onCancel?.();
          }}
          size="sm"
          text
        >
          Cancel
        </Button>
        <Button disabled={!isDirty} loading={isSubmitting} size="sm" type="submit">
          {isCreate ? 'Create API Definition' : 'Save'}
        </Button>
      </Flex>
    </form>
  );
}
