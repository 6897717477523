import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDarkMode, useLogo } from '@Hub/Header/utils/hooks';

import useMediaQuery from '@core/hooks/useMediaQuery';
import useOAuthFlow from '@core/hooks/useOAuthFlow';
import { InitializeReferenceStore, useProjectStore, useReferenceStore } from '@core/store';
import { oauthStateSplitToken } from '@core/store/Reference/Auth/oauth-types';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Spinner from '@ui/Spinner';

import classes from './style.module.scss';

export default function OAuthRedirect() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const code = query.get('code');
  const state = query.get('state');
  const [securitySchemeKey] = state?.split(oauthStateSplitToken) || ['', ''];
  const [status] = useReferenceStore(s => [s.auth.oauth.schemes[securitySchemeKey]?.draftState.status || 'failure']);

  const { authCodeWindowResult, makeTokenRequest } = useOAuthFlow({
    securitySchemeKey,
    source: 'redirect',
    state,
  });

  useEffect(() => {
    makeTokenRequest(code);
  }, [code, makeTokenRequest, state]);

  const [headerType] = useProjectStore(s => [s.data.appearance.header.type]);
  const { whiteSrc, src } = useLogo();
  const userScheme = useDarkMode();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [logoSrc, setLogoSrc] = useState<string | null | undefined>(null);
  const computedSrc = logoSrc || src;

  const Logo = computedSrc ? <img alt="" height="40" src={computedSrc} width="auto" /> : null;

  useEffect(() => {
    // If solid header, use primary logo
    // Else if the color scheme is light, uuse primary logo
    // Else use the white logo
    setLogoSrc(
      headerType !== 'line'
        ? src
        : userScheme === 'light' || (userScheme === 'system' && !prefersDarkMode)
          ? src
          : whiteSrc,
    );
  }, [headerType, prefersDarkMode, src, userScheme, whiteSrc]);

  return (
    <InitializeReferenceStore isOAuthRedirectPage>
      <Flex
        align="center"
        className={`rm-OAuthRedirect ${classes.OAuthRedirect}`}
        gap="md"
        justify="center"
        layout="col"
        tag="section"
      >
        {authCodeWindowResult ? (
          <>
            <Flex align="center" gap="xl" layout="col">
              {Logo}
              <Icon name={status === 'failure' ? 'alert-triangle' : 'check'} size="lg" />
            </Flex>
            <Flex align="center" gap="sm" layout="col">
              <h1 className={classes['OAuthRedirect-heading']}>
                {status === 'failure' ? 'Authorization Error' : 'Authorization Complete'}
              </h1>
              <p className={classes['OAuthRedirect-paragraph']}>{authCodeWindowResult}</p>
            </Flex>
          </>
        ) : (
          <>
            <Flex align="center" gap="xl" layout="col">
              {Logo}
              <Spinner size="lg" />
            </Flex>
            <Flex align="center" gap="sm" layout="col">
              <h1 className={classes['OAuthRedirect-heading']}>Authorizing…</h1>
              <p className={classes['OAuthRedirect-paragraph']}>
                Please do not close this window until authorization is complete.
              </p>
            </Flex>
          </>
        )}
      </Flex>
    </InitializeReferenceStore>
  );
}
