import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { useSuperHubEditorFormContext } from '.';

interface EditorKeyContextValue {
  /**
   * Unique key for the MarkdownEditor.
   */
  editorKey: string;
  /**
   * Refreshes the editor key to force a re-render of the MarkdownEditor.
   */
  refreshEditorKey: () => void;
}

const EditorKeyContext = createContext<EditorKeyContextValue | null>(null);

/**
 * Provides `EditorKeyContext` and makes it available to all children.
 */
export const EditorKeyContextProvider: React.FC = ({ children }) => {
  // Default to a static value so the state remains stable on SSR and 1st client render
  const [editorKey, setEditorKey] = useState('static-value-placeholder');

  const { pathname } = useLocation();
  const {
    formState: { defaultValues },
  } = useSuperHubEditorFormContext();

  // Generate a unique key for the editor
  const refreshEditorKey = useCallback(() => {
    setEditorKey(uuid());
  }, []);

  // Default to refreshing the editorKey when the pathname changes or when the form's default value changes
  useEffect(() => {
    refreshEditorKey();
  }, [refreshEditorKey, pathname, defaultValues?.content?.body]);

  return (
    <EditorKeyContext.Provider
      value={useMemo(
        () => ({
          refreshEditorKey,
          editorKey,
        }),
        [editorKey, refreshEditorKey],
      )}
    >
      {children}
    </EditorKeyContext.Provider>
  );
};

/**
 * Store and refresh a unique key for the MarkdownEditor.
 *
 * The key is used to force the MarkdownEditor to re-render when the
 * content changes. This is necessary because the MarkdownEditor doesn't
 * update its internal state when the value of it's `doc` prop changes.
 * @see https://github.com/readmeio/readme/blob/520a0359f5516bc6924b60e89d0e68047037da24/packages/react/src/ui/MarkdownEditor/docs.mdx?plain=1#L28
 *
 * By default, the key is updated when the pathname or form default values change.
 * But it can also be refreshed manually by calling `refreshEditorKey`.
 */
export function useEditorKeyContext() {
  const value = useContext(EditorKeyContext);
  if (!value) throw new Error('EditorKeyContext.Provider is missing');
  return value;
}
