import React, { useContext } from 'react';

import { ProjectContext, UserContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';

import GraphQLPlayground from '@ui/GraphQLPlayground';

export default function GraphQL() {
  const { project } = useContext(ProjectContext);
  const { user } = useContext(UserContext) || {};
  const { isServer } = useEnvInfo();

  if (isServer) {
    return null;
  }

  return (
    <main>
      <GraphQLPlayground url={project.graphqlSchema} user={user} />
    </main>
  );
}
