import type { APIDefinitionsReadType } from '@readme/api/src/mappings/apis/types';

import React from 'react';

import Badge from '@ui/Badge';

const ApiDefinitionStatusBadge: React.FC<{ status: APIDefinitionsReadType['upload']['status'] }> = ({ status }) => {
  const isErrored = ['failed', 'failed_update'].includes(status);
  if (isErrored) {
    return <Badge kind="failure">Error</Badge>;
  }

  return null;
};

export default ApiDefinitionStatusBadge;
