import React from 'react';
import { Link } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { useAPIDesignerStore, useSuperHubStore } from '@core/store';

import Alert from '@ui/Alert';
import ParameterGroup from '@ui/APIDesigner/ParameterGroup';
import RequestBodyEditor from '@ui/APIDesigner/RequestBodyEditor';
import Badge from '@ui/Badge';
import Flex from '@ui/Flex';
import Skeleton from '@ui/Skeleton';

import ParameterGroupSkeleton, { SKELETON_WIDTH } from './ParameterGroup/Skeleton';
import styles from './style.module.scss';

interface Props {
  /** Whether API Designer should be in disabled state (i.e. if certain conditions in the spec limit ability to manually edit) */
  disabled?: boolean;

  pathInputRef?: React.RefObject<HTMLInputElement>;
}

const APIDesigner = ({ disabled, pathInputRef }: Props) => {
  const bem = useClassy(styles, 'APIDesigner');
  const [apiObject, setApiObject] = useAPIDesignerStore(s => [s.apiObject, s.setApiObject]);
  const [apiDesignerLimited] = useSuperHubStore(s => [s.document.apiDesignerLimited]);

  // If editing is disabled at the project level, show a different message
  const title = apiDesignerLimited?.byProjectLevel ? 'Editing limited by admin' : 'Some items cannot be edited';
  const subtitle = apiDesignerLimited?.byProjectLevel ? (
    <>
      Admins can re-enable editing from the{' '}
      <Link className={bem('-alert-link')} to="/update/reference">
        API Definitions page
      </Link>
      .
    </>
  ) : (
    <>
      This endpoint uses a feature that’s not supported, but you can continue to its edit Markdown or your API
      definition by{' '}
      <Link className={bem('-alert-link')} to="/update/reference">
        uploading or syncing your OAS file
      </Link>
      .
    </>
  );

  if (disabled) {
    return (
      <Flex align="stretch" className={bem('&', '_disabled')} gap="sm" layout="col">
        <Alert subtitle={subtitle} title={title} type="neutral" />
        <Skeleton className={bem('-skeleton-header')} width={SKELETON_WIDTH} />
        <ParameterGroupSkeleton className={bem('-skeleton')} />
      </Flex>
    );
  }

  if (!apiObject || !apiObject.schema) {
    return null;
  }

  return (
    <Flex align="stretch" className={bem('&')} gap="0" layout="col" tag="section">
      <ParameterGroup in="path" pathInputRef={pathInputRef} />
      <ParameterGroup in="query" />
      <ParameterGroup in="header" />
      <RequestBodyEditor api={apiObject} updateAPI={setApiObject} />
      <Flex align="center" className={bem('-beta')} gap="0.5em" justify="center" tag="aside">
        <Badge kind="alert">Beta</Badge>{' '}
        <span>
          API Designer is currently in beta.{' '}
          <a className={bem('-beta-email')} href="mailto:beta@readme.com">
            Email us
          </a>{' '}
          for feedback or issues.
        </span>
      </Flex>
    </Flex>
  );
};

export default APIDesigner;
