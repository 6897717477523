import type { EndpointDataType } from '@readme/api/src/mappings/page/reference/types';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { apiDesignerStore, useAPIDesignerStore } from '@core/store';

interface ApiDesignerValidationProps<Value> {
  /**
   * Called when the field value changes.
   * @note If a `validate.beforeChange` rule is provided, this will only be called if the
   * next value passes validation.
   */
  handleChange: (value: Value) => void;
  /**
   * A unique name for the field.
   */
  name: string;
  /**
   * Validation rules for the field.
   */
  validate: {
    /** Validate an input's onChange handler value before calling the handleChange callback */
    beforeChange?: (value: Value, formState?: EndpointDataType) => string | true;
    /** Error message to display if the field is required and empty */
    required?: string;
  };
  /**
   * The current value of the field.
   */
  value: Value;
}

/**
 * Hook to handle validation and error state for API Designer form fields.
 *
 * Because the API Designer form fields are not registered with the SuperHub editor's
 * React Hook Form context, we need to manage field-level validation and error state manually.
 *
 * Fields may have a `validate.beforeChange` rule that will validate the next value before
 * calling the `handleChange` callback. Some fields in the API Designer need this behavior
 * to prevent data loss in the OAS document state if next values overwrite the current state.
 */
export default function useApiDesignerValidation<Value>({
  name,
  validate,
  value: stateValue,
  handleChange,
}: ApiDesignerValidationProps<Value>) {
  // If a `validate.beforeChange` rule is provided this `displayValue` will still represent the next
  // value regardless of whether it passes validation.
  const [displayValue, setDisplayValue] = useState(stateValue);
  const [setError, clearErrors, errors] = useAPIDesignerStore(s => [s.setError, s.clearErrors, s.errors]);

  // Check that the field is valid before calling the handleChange callback
  const checkValidityAndUpdate = useCallback(
    (value: Value) => {
      if (validate.required && !value) {
        setError(name, validate.required);
        return;
      }

      if (validate.beforeChange) {
        // We pass the current form state to the validation function to ensure the validation
        // logic has access to the latest Api Designer state, avoiding potential stale values.
        const formState = apiDesignerStore.getState().apiObject;
        const validation = validate.beforeChange(value, formState);
        if (validation !== true) {
          setError(name, validation);
          return;
        }
      }

      clearErrors(name);
      handleChange(value);
    },
    [clearErrors, handleChange, name, setError, validate],
  );

  useEffect(() => {
    setDisplayValue(stateValue);
  }, [stateValue]);

  // Clear the field error when the consumer unmounts
  useEffect(() => () => clearErrors(name), [clearErrors, name]);

  const onChange = (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const value = event.currentTarget.value as Value;
    setDisplayValue(value);
    checkValidityAndUpdate(value);
  };

  const checkValidity = useCallback(() => {
    checkValidityAndUpdate(displayValue);
  }, [checkValidityAndUpdate, displayValue]);

  // Because the field input is not registered with React Hook Form, we use built-in HTML `required`
  // attribute to trigger an error message on form submission if the field is required.
  const requiredProps = useMemo(
    () =>
      validate.required
        ? {
            required: true,
            onInvalid: (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
              event.preventDefault(); // Prevents the default browser validation tooltip
              setError(name, validate.required || 'Required');
            },
          }
        : {},
    [name, validate.required, setError],
  );

  return {
    error: errors[name],
    checkValidity,
    field: {
      value: displayValue,
      onChange,
      ...requiredProps,
    },
  };
}
