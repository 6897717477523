/**
 * When creating a new API definition, we provide an empty OAS schema to start
 * with. The form will be pre-filled with this schema, which can be modified by
 * the user before saving.
 */
export const defaultSchema = {
  openapi: '3.0.0',
  info: {
    version: '1.0.0',
    title: '',
  },
  servers: [
    {
      url: '',
    },
  ],
  tags: [],
  paths: {
    '/new-endpoint': {
      get: {
        summary: 'New Endpoint',
        description: 'This is your first endpoint! Edit this page to start documenting your API.',
        operationId: 'get_new-endpoint',
        responses: {
          '200': {
            description: 'Successful response',
          },
        },
      },
    },
  },
  components: {
    securitySchemes: {},
  },
};
