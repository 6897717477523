import type { ItemDragType } from '../DragDrop';

import React, { useEffect, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useDragAndDropContext from '@core/hooks/useDragAndDropContext';

import styles from './index.module.scss';

export interface PageNavDividerProps {
  className?: string;

  /**
   * When true, divider collapses during certain events, like when a dragging
   * operation of a `PageNavCategory` is in progress. Default is `true`.
   */
  collapsible?: boolean;

  /**
   * Draws a visible line.
   */
  includeLine?: boolean;

  /**
   * Controls the gap size.
   */
  size?: 'lg' | 'md' | 'sm' | 'xs';
}

/**
 * Vertical spacer used to create separation between PageNav child elements.
 */
export default React.memo(function PageNavDivider({
  className,
  includeLine = false,
  collapsible = true,
  size = 'xs',
}: PageNavDividerProps) {
  const bem = useClassy(styles, 'PageNavDivider');
  const { monitor } = useDragAndDropContext();
  const [isDragging, setIsDragging] = useState(false);
  const [itemType, setItemType] = useState<ItemDragType | null>(null);

  useEffect(() => {
    const unsubscribe = monitor?.subscribeToStateChange(() => {
      setIsDragging(monitor.isDragging());
      setItemType(monitor.getItemType() as typeof itemType);
    });
    return () => unsubscribe?.();
  }, [monitor]);

  return (
    <hr
      className={`PageNavDivider ${bem(
        '&',
        className,
        includeLine && '_line',
        size && `_${size}`,
        collapsible && itemType === 'category' && isDragging && '_dragging',
      )}`}
    />
  );
});
