import React from 'react';

import { useSuperHubStore } from '@core/store';

import EmptyState from '@routes/SuperHub/components/EmptyState';

/**
 * Renders the empty state for Custom Pages.
 */
export default function EmptyCustomPage() {
  const isSuperHubAdmin = useSuperHubStore(s => s.isSuperHubAdmin);

  return <EmptyState action={isSuperHubAdmin ? '/create/page' : undefined} section="customPage" />;
}
