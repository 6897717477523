import type { UseInfiniteLoaderDataOptions } from './useInfiniteLoaderData';
import type { CSSProperties } from 'react';

import React, { useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { VariableSizeList as VirtualList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

// eslint-disable-next-line no-restricted-imports
import type { ProjectContextValue, VersionContextValue } from '@core/context';
// eslint-disable-next-line no-restricted-imports
import { ProjectContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';

import Spinner from '@ui/Spinner';

import classes from './index.module.scss';
import PaddedInnerElement from './PaddedInnerElement';
import useInfiniteLoaderData from './useInfiniteLoaderData';
import WithAutoSizer from './WithAutoSizer';

// Shim in a light context to provide the paddingBlock value to the PaddedInnerElement
interface InfiniteLoaderListContextProps {
  paddingBlock: number;
}
export const InfiniteLoaderListContext = React.createContext<InfiniteLoaderListContextProps>({
  paddingBlock: 0,
});

interface InfiniteLoaderListProps<T>
  extends Pick<UseInfiniteLoaderDataOptions, 'paginationType' | 'perPage' | 'threshold'> {
  /**
   * A function that returns a React element to be rendered for each item in the list
   */
  children: (props: { index: number; item: T }) => React.ReactNode;
  /**
   * Additional class names to apply to the list container
   */
  className?: string;
  /**
   * The default height of each item in the list.
   * - When the list height auto-sizes, this value will be used as the default height for the SSR render and replaced
   * with a dynamic height after the component mounts.
   * - When the list height is sized by the visibileItemThreshold, this value will be used as the initial
   * height for the list container before the items load.
   */
  defaultHeight?: number;
  /**
   * The API endpoint to fetch data from
   */
  endpoint: string;
  /**
   * An optional base URL to prefix the `endpoint` prop with. If not provided,
   * `endpoint` will be dynamically prefixed with a default base URL.
   */
  endpointBaseUrl?: string;
  /**
   * The height of each item in the list. Can be a a static number, or function that returns a height for each item
   * in the list which allows for dynamic item heights.
   */
  itemHeight: number | ((item: T) => number);
  /**
   * Static height of the list container
   */
  listHeight?: number;
  /**
   * A callback that is called every time a new page of data is loaded
   */
  onLoad?: (data: T[]) => void;
  /**
   * A callback that is called when the loading state changes
   */
  onLoadingChange?: (isLoading: boolean) => void;
  /**
   * A callback that is called once after the first page of data is loaded
   */
  onReady?: () => void;
  /**
   * Top/bottom padding to add to the list container
   */
  paddingBlock?: number;
  /**
   * Renders the provided component when list is empty.
   */
  renderOnEmpty?: React.ReactNode;
  /**
   * Clear the SWR cache for the paginated data before the component is unmounted.
   */
  resetBeforeUnmount?: boolean;
  /**
   * Maximum number of items to display in the list before a scrollbar is added.
   * If provided, the list height will adjust to fit the number of items until
   * the threshold is reached and scrollbar is added. If not provided, the list
   * will automatically adjust its height to fit its parent container.
   */
  visibileItemThreshold?: number;
}

export interface InfiniteLoaderListRef<T> {
  /**
   * The current SWR data in the paginated response format
   */
  data: ReturnType<typeof useInfiniteLoaderData<T>>['data'];
  /**
   * SWR mutate function to manually trigger a revalidation of the data
   */
  mutate: ReturnType<typeof useInfiniteLoaderData<T>>['mutate'];
  /**
   * Scroll to the top of the list
   */
  scrollToTop: () => void;
}

/**
 * An infinite scrolling virtual list that consumes paginated APIv2 data
 */
const InfiniteLoaderList = React.forwardRef(function InfiniteLoaderList<ItemType>(
  {
    children,
    className,
    defaultHeight = 200,
    endpoint,
    endpointBaseUrl,
    itemHeight,
    listHeight,
    visibileItemThreshold,
    onLoad,
    onReady,
    onLoadingChange,
    paddingBlock = 0,
    paginationType,
    perPage,
    renderOnEmpty,
    resetBeforeUnmount = false,
    threshold,
  }: InfiniteLoaderListProps<ItemType>,
  forwardedRef: React.Ref<InfiniteLoaderListRef<ItemType>>,
) {
  const bem = useClassy(classes, 'InfiniteLoaderList');

  const listRef = useRef<VirtualList | null>(null);
  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { version } = useContext(VersionContext) as VersionContextValue;

  const [listContainerHeight, setListContainerHeight] = useState(listHeight || 0);

  useEffect(() => {
    if (listContainerRef.current) {
      setListContainerHeight(listContainerRef.current.clientHeight);
    }
  }, []);

  const baseUrl = endpointBaseUrl || `/${project.subdomain}/api-next/v2/versions/${version}`;
  const url = `${baseUrl}${endpoint}`;
  const { data, items, isReady, isLoading, infiniteLoaderProps, mutate, reset } = useInfiniteLoaderData<ItemType>(url, {
    paginationType,
    perPage,
    threshold,
  });

  // Expose SWR values to parent components
  useImperativeHandle(
    forwardedRef,
    () => ({
      mutate,
      data,
      scrollToTop: () => {
        listRef.current?.scrollTo(0);
      },
    }),
    [mutate, data],
  );

  useEffect(() => {
    if (isReady) onReady?.();
  }, [isReady, onReady]);

  useEffect(() => {
    onLoad?.(items);
  }, [items, onLoad]);

  useEffect(() => {
    onLoadingChange?.(isLoading);
  }, [isLoading, onLoadingChange]);

  useEffect(() => {
    return () => {
      if (resetBeforeUnmount) {
        reset();
      }
    };
  }, [reset, resetBeforeUnmount]);

  const { itemCount, isItemLoaded } = infiniteLoaderProps;

  const itemSizeCallback = useMemo(
    () => (index: number) => (typeof itemHeight === 'function' ? itemHeight(items[index]) : itemHeight),
    [items, itemHeight],
  );

  const isAutoSizeEnabled = !listHeight && !visibileItemThreshold;

  const initialHeight = useMemo(() => {
    if (isAutoSizeEnabled) return listContainerHeight;
    if (listHeight) return listHeight;
    return defaultHeight;
  }, [defaultHeight, isAutoSizeEnabled, listContainerHeight, listHeight]);

  // Used to calculate the static height of the list container when `listHeight` or `visibileItemThreshold` props
  // are provided
  const staticHeight = useMemo(() => {
    if (visibileItemThreshold) {
      // height will adjust to fit the number of items until the visibileItemThreshold is reached
      const maxItems = Math.min(visibileItemThreshold, itemCount);
      let totalHeight = Array.from({ length: maxItems }).reduce((sum: number, _, index) => {
        return sum + itemSizeCallback(index);
      }, 0);

      // Make half of the next item after the threshold visible to give the user some affordance to scroll
      if (itemCount > visibileItemThreshold) {
        totalHeight += itemSizeCallback(visibileItemThreshold) * 0.5;
      }

      return totalHeight;
    }

    // If listHeight is provided, use that. If not, use 200 as a default height.
    return listHeight || listContainerHeight;
  }, [visibileItemThreshold, listHeight, listContainerHeight, itemCount, itemSizeCallback]);

  const listStyles = useMemo(
    () =>
      ({
        '--InfiniteLoaderList-intial-height': `${initialHeight}px`,
      }) as CSSProperties,
    [initialHeight],
  );

  return (
    <InfiniteLoaderListContext.Provider value={{ paddingBlock }}>
      <div ref={listContainerRef} className={bem('&')}>
        <div className={bem(!isReady && '-content_loading', className)} style={listStyles}>
          {!isReady ? (
            <Spinner />
          ) : !itemCount ? (
            renderOnEmpty
          ) : (
            <InfiniteLoader {...infiniteLoaderProps}>
              {({ onItemsRendered, ref }) => (
                <WithAutoSizer defaultHeight={defaultHeight} isEnabled={isAutoSizeEnabled}>
                  <VirtualList
                    ref={node => {
                      ref(node);
                      listRef.current = node;
                    }}
                    height={staticHeight} // this will be overridden by WithAutoSizer if isAutoSizeEnabled is true
                    innerElementType={PaddedInnerElement}
                    itemCount={itemCount}
                    itemData={items}
                    itemSize={itemSizeCallback}
                    onItemsRendered={onItemsRendered}
                    width="100%"
                  >
                    {({ index, style }) => {
                      const item = items[index];
                      const isItemLoading = !isItemLoaded(index);
                      return (
                        <li
                          className={bem('-item', isItemLoading && '-item_loading')}
                          style={{ ...style, top: `calc(${style.top}px + ${paddingBlock}px)` }}
                        >
                          {isItemLoading ? <Spinner size="sm" /> : children({ item, index })}
                        </li>
                      );
                    }}
                  </VirtualList>
                </WithAutoSizer>
              )}
            </InfiniteLoader>
          )}
        </div>
      </div>
    </InfiniteLoaderListContext.Provider>
  );
}) as <T>(props: InfiniteLoaderListProps<T> & { ref?: React.Ref<InfiniteLoaderListRef<T>> }) => React.ReactElement;

export default InfiniteLoaderList;
