import type { ReadReferenceType } from '@readme/api/src/mappings/page/reference/types';
import type { PageSchema } from '@readme/backend/models/page/types';
import type { OASDocument } from 'oas/types';
import type { PartialDeep } from 'type-fest';

export function normalizeGitDocToMongo(data?: ReadReferenceType['data']) {
  if (!data) return undefined;

  return {
    _id: data.uri,
    api: {
      method: data.api.method as PageSchema['api']['method'],
      url: data.api.path,
      params: [],
    },
    body: data.content.body ?? undefined,
    deprecated: data.state === 'deprecated',
    excerpt: data.content.excerpt ?? undefined,
    slug: data.slug,
    swagger: {
      path: data.api.path,
    },
    title: data.title,
    type: data.type as PageSchema['type'],
  } satisfies PartialDeep<PageSchema>;
}

export function normalizeGitOasToMongo(data: ReadReferenceType['data']) {
  return {
    _id: data.api.uri,
    ...(data.api.schema as OASDocument),
  };
}
