import type { TreeNodeCategory, TreeNodePage } from './types';

import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { changelogCategory, customPageCategory } from './Category';

/**
 * Returns a helper function that observes router params in order to determine
 * whether an ephemeral page should be added to a parent category or page.
 */
export default function useEphemeralPageData() {
  const { routeParentCategory, routeParentPage, section: routeSection } = useParams<SuperHubRouteParams>();
  const isCreateNewPage = useSuperHubStore(s => s.editor.isCreateNewPage);
  const isChangelog = routeSection === 'changelog';

  return useCallback(
    /**
     * Checks if a new page is being added to the provided `parent`. If so, an
     * object is returned that contains information about what's being added.
     */
    (parent: TreeNodeCategory | TreeNodePage) => {
      const parentSlug = 'slug' in parent.data ? parent.data.slug : parent.data.title;

      // Only render when new page flag is enabled.
      if (!isCreateNewPage) return null;

      // Only render if parent category or page matches at least one valid parent.
      if (![routeParentCategory, routeParentPage, customPageCategory, changelogCategory].includes(parentSlug))
        return null;

      // When parent is a category, only render if no parent page segment exists
      // because that would signify a new sub-page instead.
      const parentIsCategory = [routeParentCategory, customPageCategory, changelogCategory].includes(parentSlug);
      if (parentIsCategory && routeParentPage) return null;

      return {
        label: isChangelog ? 'New Post' : parentIsCategory ? 'New Page' : 'New Subpage',
        type: isChangelog ? 'changelog' : 'page',
      } as const;
    },
    [isChangelog, isCreateNewPage, routeParentCategory, routeParentPage],
  );
}
