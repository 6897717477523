import React, { useCallback } from 'react';

import { useSuperHubStore } from '@core/store';

import { useSuperHubContext } from '@routes/SuperHub/components/Context';

import { PageNavCreateNew } from '@ui/Dash/PageNav';

import { useSidebarNavContext } from './Context';

/**
 * Renders the sidebar nav footer.
 */
export const SidebarNavFooter = React.memo(function SidebarNavFooter() {
  const [isCreateNewPage, routeSection] = useSuperHubStore(s => [s.editor.isCreateNewPage, s.routeSection]);
  const { ephemeralCategory, setEphemeralCategory } = useSidebarNavContext();
  const { browserRouterHistory } = useSuperHubContext();

  const handleCreateCategory = useCallback(() => {
    setEphemeralCategory({
      pages: [],
      title: '',
    });
  }, [setEphemeralCategory]);

  const handleCreatePage = useCallback(() => {
    browserRouterHistory.push(`/create/${routeSection}`);
  }, [browserRouterHistory, routeSection]);

  return routeSection === 'changelog' || routeSection === 'page' ? (
    <PageNavCreateNew disabled={isCreateNewPage} onClick={handleCreatePage} type={routeSection} />
  ) : (
    <PageNavCreateNew disabled={!!ephemeralCategory} onClick={handleCreateCategory} />
  );
});
