import type { SchemaType } from '.';
import type { ComponentProps } from 'react';

import React, { forwardRef, useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';

import Tooltip from '@ui/Tooltip';

import styles from './style.module.scss';

import { validatedSchemaType } from '.';

interface TypeMenuButtonProps extends ComponentProps<'button'> {
  schema: {
    type: SchemaType;
  };
}

const TypeMenuButton = forwardRef<HTMLButtonElement, TypeMenuButtonProps>(function TypeMenuButton(
  { schema, className, ...rest },
  ref,
) {
  const bem = useClassy(styles, 'TypeMenuButton');

  const errorMessage = useMemo(() => {
    const { type, typeLabel, format, formatLabel } = validatedSchemaType(schema);

    if (type !== typeLabel) {
      return `"${type}" is not a valid schema type`;
    } else if (format !== formatLabel) {
      return `"${format}" is not a valid schema format`;
    }
    return undefined;
  }, [schema]);

  return (
    <Tooltip content={errorMessage} disabled={!errorMessage} placement="top">
      <button ref={ref} className={bem(className, '&', !!errorMessage && '_error')} type="button" {...rest} />
    </Tooltip>
  );
});

export default TypeMenuButton;
