import type { PageNavCategoryProps, PageNavItemProps } from '..';
import type { ItemDragObject, ItemDragType } from './useItemDrag';
import type { DragSourceMonitor, XYCoord } from 'react-dnd';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDragLayer } from 'react-dnd';

import useClassy from '@core/hooks/useClassy';
import useDragAndDropContext from '@core/hooks/useDragAndDropContext';

import Flex from '@ui/Flex';

import EndpointBadge from '../Item/EndpointBadge';
import StatusIcon from '../Item/StatusIcon';

import styles from './Preview.module.scss';

type ItemPreviewProps = Pick<PageNavItemProps, 'endpoint' | 'status' | 'type'> & {
  itemType?: ItemDragType;
  label?: PageNavCategoryProps['label'] | PageNavItemProps['label'];
};

function PageNavItemPreview(props: ItemPreviewProps) {
  const bem = useClassy(styles, 'DragDropPreview');
  const prevProps = useRef({ ...props });

  // Keep our prev props ref in sync with the last valid set of props. We need
  // this to prevent wiping out the preview content when a drag operation ends
  // and the collected props return undefined. We want to continue displaying
  // the drag preview after it ends and only reset it when a new drag begins.
  useEffect(() => {
    if (props.label) {
      prevProps.current = { ...props };
    }
  }, [props]);

  const { endpoint, itemType, label, status, type } = prevProps.current;
  const typeLabel = useMemo(() => {
    return {
      '': 'item',
      item: 'page',
      category: 'category',
    }[itemType || ''];
  }, [itemType]);

  if (!label) return null;
  return (
    <>
      <span className={bem('-preview-title')}>Move {typeLabel}</span>
      <Flex align="center" className={bem('&-preview-content')} gap="0">
        {itemType === 'category' ? (
          <>
            <span className={bem('&-preview-label')}>{label}</span>
          </>
        ) : (
          <>
            <StatusIcon status={status} type={type} />
            <span className={bem('&-preview-label')}>{label}</span>
            <EndpointBadge endpoint={endpoint} />
          </>
        )}
      </Flex>
    </>
  );
}

function getOffsetStyle(position: XYCoord | null | undefined) {
  if (!position) return {};

  const [offsetX, offsetY] = [-2, -2];
  const { x, y } = position;
  return {
    transform: `translate(${x + offsetX}px, ${y + offsetY}px)`,
  };
}

/**
 * Renders the dragging item preview that is overlayed on top of the document
 * body and follows the pointer's position as it is dragged.
 */
export default function DragDropPreview() {
  const bem = useClassy(styles, 'DragDropPreview');
  const { id: dndProviderId } = useDragAndDropContext();
  const [dropped, setDropped] = useState(false);

  const collected = useDragLayer(monitor => ({
    clientOffset: monitor.getClientOffset(),
    didDrop: (monitor as DragSourceMonitor).didDrop(),
    initialOffset: monitor.getInitialClientOffset(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem() as ItemDragObject,
    itemType: monitor.getItemType() as ItemDragType,
  }));

  // This takes our "collected" props above and prevents updating previews that
  // are outside the context of this drag/drop provider.
  const isSameDndProvider = collected.item?.dndProviderId === dndProviderId;
  const startPosition = useRef<XYCoord>();
  const lastPosition = useRef<XYCoord>();
  const { clientOffset, didDrop, isDragging, item, itemType } = useMemo(() => {
    if (!isSameDndProvider) return {};
    if (collected.initialOffset) {
      startPosition.current = collected.initialOffset ?? startPosition.current;
    }
    if (collected.clientOffset) {
      lastPosition.current = collected.clientOffset ?? lastPosition.current;
    }
    return {
      clientOffset: collected.clientOffset,
      didDrop: collected.didDrop,
      initialOffset: collected.initialOffset,
      isDragging: collected.isDragging,
      item: collected.item,
      itemType: collected.itemType,
    };
  }, [collected, isSameDndProvider]);

  // Toggle class on document body when dragging, mainly to update the cursor.
  useEffect(() => {
    if (!isSameDndProvider) return undefined;
    document.body.classList.toggle(bem('-body_dragging'), isDragging);
    return () => document.body.classList.remove(bem('-body_dragging'));
  }, [bem, isSameDndProvider, isDragging]);

  // We need to know whether the drag operation ended with a valid drop so we
  // can transition the preview differently than when no drop occurred. We use
  // another "dropped" state to keep track of this and only update it when a
  // drag operation is in progress. When a drag ends, the "didDrop" monitor will
  // be "undefined", giving us a way to distinguish when a drag ended with or
  // without a drop.
  useEffect(() => {
    if (didDrop === undefined) return;
    setDropped(didDrop);
  }, [didDrop]);

  // Generate the translation style based on the drag item's offset.
  const offsetStyle = useMemo(() => {
    const returnPosition = dropped ? lastPosition : startPosition;
    return getOffsetStyle(clientOffset || returnPosition.current);
  }, [clientOffset, dropped]);

  return (
    <div
      className={bem('&', isDragging && '_dragging', dropped && '_dropped')}
      data-testid="drag-drop-preview"
      style={offsetStyle}
    >
      <Flex align="stretch" className={bem('-content')} gap="0" justify="start" layout="col">
        <PageNavItemPreview itemType={itemType} {...item?.meta} />
      </Flex>
    </div>
  );
}
