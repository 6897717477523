import type { TreeNodePage } from './types';
import type { GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import lodashCamelCase from 'lodash/camelCase';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';

import { useSuperHubContext } from '@routes/SuperHub/components/Context';
import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { PageNavItem, getPageNavItemElementId } from '@ui/Dash/PageNav';
import type { PageNavItemProps } from '@ui/Dash/PageNav';

import { useConfirmDeletionContext } from '../ConfirmDeletion';
import { useDisabledStateContext } from '../DisabledStateContext';

/**
 * Returns the correct page item type based on the provided model.
 */
function getPageItemType(page: GitSidebarPage) {
  switch (page.type) {
    case 'api_config':
      return lodashCamelCase(`realtime-${page.slug}`) as
        | 'realtimeAuthentication'
        | 'realtimeGettingStarted'
        | 'realtimeMyRequests';
    case 'link':
      return page.link_external ? 'linkExternal' : 'link';
    case 'changelog':
      return 'changelog';
    default:
      return 'page';
  }
}

export interface SidebarNavPageProps {
  node: TreeNodePage;
}

export const SidebarNavPage = React.memo(function SidebarNavPage({ node }: SidebarNavPageProps) {
  const page = node.data;
  const { routeParentPage, slug: routeSlug } = useParams<SuperHubRouteParams>();
  const { browserRouterHistory } = useSuperHubContext();
  const [duplicatePage, isCreateNewPage, routeSection, reorderPage] = useSuperHubStore(s => [
    s.sidebar.duplicatePage,
    s.editor.isCreateNewPage,
    s.routeSection,
    s.sidebar.reorderPage,
  ]);
  const { setPendingDelete } = useConfirmDeletionContext();

  // Grab the parent category title.
  const categoryTitle = node.categoryTitle;
  const hasNewSubPage = isCreateNewPage && page.slug === routeParentPage;

  /**
   * Contains the configuration of page menu actions, which toggles actions
   * on/off depending on the page type, e.g. `docs`, `page`, etc.
   */
  const configureActions = useMemo<PageNavItemProps['configureActions']>(() => {
    return ['changelog', 'custom_page'].includes(page.type)
      ? {
          add: { hidden: true },
          // TODO: enable duplication similar to how we duplicate guide pages
          duplicate: { hidden: true },
          move: { hidden: true },
        }
      : undefined;
  }, [page.type]);

  const handleAction = useCallback<NonNullable<PageNavItemProps['onAction']>>(
    ({ action }) => {
      switch (action) {
        case 'add': {
          browserRouterHistory.push(`/create/${routeSection}/${categoryTitle}/${page.slug}`);
          break;
        }
        case 'delete': {
          setPendingDelete(page);
          break;
        }
        case 'duplicate':
          duplicatePage(page);
          break;
        default:
          break;
      }
    },
    [browserRouterHistory, categoryTitle, duplicatePage, page, routeSection, setPendingDelete],
  );

  const handleMove = useCallback<NonNullable<PageNavItemProps['onMove']>>(
    ({ source, target }) => {
      reorderPage(source, target);
    },
    [reorderPage],
  );

  const handleToggle = useCallback<NonNullable<PageNavItemProps['onToggle']>>(() => {
    node.toggleOpen();
  }, [node]);

  const isSidebarDisabled = useDisabledStateContext();

  return (
    <PageNavItem
      active={page.slug === routeSlug}
      aria-owns={page.pages?.map(subpage => getPageNavItemElementId(subpage)).join(' ') || undefined}
      aria-posinset={node.position + 1}
      aria-setsize={node.positionLength}
      categoryId={node.categoryUri}
      childPages={page.pages}
      configureActions={configureActions}
      deprecated={page.deprecated}
      disabled={!page.slug || isSidebarDisabled}
      disableDnd={['changelog', 'custom_page'].includes(page.type)}
      endpoint={page.type === 'endpoint' ? page.api_method : undefined}
      hasNewSubPage={hasNewSubPage}
      id={page.uri}
      isPrivate={page.hidden}
      label={page.title}
      level={node.nestingLevel}
      modified={page.updatedAt}
      onAction={handleAction}
      onMove={handleMove}
      onToggle={handleToggle}
      parentId={node.parentUri}
      position={node.position}
      renderable={page.renderable}
      showActions={page.type !== 'api_config'}
      startOpened={node.isOpen}
      to={`/update/${routeSection}/${page.slug}`}
      type={getPageItemType(page)}
    />
  );
});
