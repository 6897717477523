import type { EndpointDataType } from '@readme/api/src/mappings/page/reference/types';

import React, { useMemo, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';
import { InitializeAPIDesignerStore, useSuperHubStore } from '@core/store';
import { ConnectFormToAPIDesignerStore } from '@core/store/APIDesigner/ConnectFormToAPIDesignerStore';
import { isReferencePage } from '@core/store/SuperHub/Document/util';

import APIDesigner from '@ui/APIDesigner';
import OperationEditor from '@ui/APIDesigner/OperationEditor';
import RequestExampleEditor from '@ui/APIDesigner/RequestExampleEditor';
import ResponseExampleEditor from '@ui/APIDesigner/ResponseExampleEditor';
import SecuritySchemePicker from '@ui/APIDesigner/SecuritySchemePicker';
import Flex from '@ui/Flex';
import { RHFGroup } from '@ui/RHF';

import HeaderInput from '../components/HeaderInput';
import MarkdownEditor from '../components/MarkdownEditor';
import WhatsNextEditor from '../components/WhatsNext';
import { useSuperHubEditorFormContext } from '../Context';
import Layout, { LayoutBody, LayoutHeader, LayoutTitle } from '../Layout';
import useFormSetFocus from '../useFormSetFocus';

import styles from './index.module.scss';
import ServerValidationError from './ServerValidationError';

/**
 * Form fields specific to the `endpoint` page type.
 */
export default function EndpointPage() {
  const bem = useClassy(styles, 'EndpointPage');
  const uid = useUniqueId('SuperHubEditorFormEndpointPage');

  const [isAPIDesignerLimited] = useSuperHubStore(s => [
    s.document.apiDesignerLimited?.byFeature || s.document.apiDesignerLimited?.byProjectLevel,
  ]);
  const {
    control,
    formState: { defaultValues },
  } = useSuperHubEditorFormContext();

  const apiObject = useMemo(() => {
    return isReferencePage(defaultValues) ? (defaultValues.api as EndpointDataType) : undefined;
  }, [defaultValues]);

  useFormSetFocus('title');

  const pathInputRef = useRef(null);

  return (
    <InitializeAPIDesignerStore apiObject={apiObject}>
      <ConnectFormToAPIDesignerStore>
        <Layout
          className={bem('&')}
          sidebar={
            <Flex align="stretch" className={bem('-request-response-sidebar')} gap="sm" justify="start" layout="col">
              <SecuritySchemePicker disabled={!!isAPIDesignerLimited} />
              <RequestExampleEditor disabled={isAPIDesignerLimited} />
              <ResponseExampleEditor disabled={isAPIDesignerLimited} />
            </Flex>
          }
        >
          <LayoutHeader>
            <LayoutTitle>
              <RHFGroup control={control} id={uid('title')} name="title" required>
                {({ field }) => <HeaderInput {...field} disabled={!!isAPIDesignerLimited} />}
              </RHFGroup>
            </LayoutTitle>
            <ServerValidationError />
            <OperationEditor disabled={!!isAPIDesignerLimited} pathInputRef={pathInputRef} />
            <RHFGroup control={control} id={uid('content-excerpt')} name="content.excerpt">
              {({ field }) => <HeaderInput {...field} disabled={!!isAPIDesignerLimited} value={field.value || ''} />}
            </RHFGroup>
          </LayoutHeader>
          <LayoutBody>
            <MarkdownEditor />
            <APIDesigner disabled={!!isAPIDesignerLimited} pathInputRef={pathInputRef} />
            <WhatsNextEditor />
          </LayoutBody>
        </Layout>
      </ConnectFormToAPIDesignerStore>
    </InitializeAPIDesignerStore>
  );
}
