import type { ItemDragObject, ItemDragType } from '.';
import type { PageNavCategoryProps, PageNavItemProps } from '..';
import type { DropTargetMonitor } from 'react-dnd';

import React from 'react';
import { useDrop } from 'react-dnd';

import useClassy from '@core/hooks/useClassy';
import useDragAndDropContext from '@core/hooks/useDragAndDropContext';

import styles from './ItemDrop.module.scss';

/**
 * Drop result object for both categories and items.
 */
export interface ItemDropResult {
  categoryId: PageNavCategoryProps['id'];
  id: string;
  parentId: PageNavItemProps['id'];
  position: number;
}

export interface ItemDropHandlerArgs {
  after: boolean;
  item: ItemDragObject;
  monitor: DropTargetMonitor<ItemDragObject, ItemDropResult>;
}

export interface ItemDropCollected {
  canDrop: boolean;
  isOver: boolean;
}

export interface ItemDropProps {
  accept: ItemDragType;
  canDrop?: (args: ItemDropHandlerArgs) => boolean;
  className?: string;
  drop: (args: ItemDropHandlerArgs) => ItemDropResult;
  id: PageNavCategoryProps['id'] | PageNavItemProps['id'];
}

/**
 * Renders two sentinel areas for a given item row that creates dropzone targets
 * that signifies an action to move the dragging item "before" or "after" the
 * current row. Consumers can use the `canDrop` handlers to control when to
 * enable or disable either "before" or "after" dropzones.
 */
export default function ItemDrop({ accept, className, canDrop: handleCanDrop = () => true, drop, id }: ItemDropProps) {
  const { id: dndProviderId } = useDragAndDropContext();
  const [before, beforeRef] = useDrop<ItemDragObject, ItemDropResult, ItemDropCollected>(
    () => ({
      accept,
      canDrop: (item, monitor) => {
        const isSameDndProvider = item?.dndProviderId === dndProviderId;
        const isSameComponent = item?.id === id;
        return (
          isSameDndProvider &&
          !isSameComponent &&
          handleCanDrop({
            after: false,
            item,
            monitor,
          })
        );
      },
      drop: (item, monitor) => {
        const result = drop({ after: false, item, monitor });
        return {
          ...result,
        };
      },
      collect: (monitor: DropTargetMonitor<ItemDragObject, ItemDropCollected>) => {
        const itemProviderId = monitor.getItem()?.dndProviderId;
        return itemProviderId === dndProviderId
          ? {
              isOver: monitor.canDrop() && monitor.isOver(),
              canDrop: monitor.canDrop(),
            }
          : {
              isOver: false,
              canDrop: false,
            };
      },
    }),
    [accept, id, dndProviderId, handleCanDrop, drop],
  );

  const [after, afterRef] = useDrop<ItemDragObject, ItemDropResult, ItemDropCollected>(
    () => ({
      accept,
      canDrop: (item, monitor) => {
        const isSameDndProvider = item?.dndProviderId === dndProviderId;
        const isSameComponent = item?.id === id;
        return (
          isSameDndProvider &&
          !isSameComponent &&
          handleCanDrop({
            after: true,
            item,
            monitor,
          })
        );
      },
      drop: (item, monitor) => {
        const result = drop({ after: true, item, monitor });
        return {
          ...result,
          position: result.position + 1,
        };
      },
      collect: (monitor: DropTargetMonitor<ItemDragObject, ItemDropCollected>) => {
        const itemProviderId = monitor.getItem()?.dndProviderId;
        return itemProviderId === dndProviderId
          ? {
              isOver: monitor.canDrop() && monitor.isOver(),
              canDrop: monitor.canDrop(),
            }
          : {
              isOver: false,
              canDrop: false,
            };
      },
    }),
    [accept, dndProviderId, id, handleCanDrop, drop],
  );

  const bem = useClassy(styles, 'ItemDrop');
  const isActive = before.canDrop || after.canDrop;

  return (
    <div className={bem('&', className, isActive && '_active')}>
      <span
        ref={beforeRef}
        className={bem('-before', before.canDrop && '-before_active', before.isOver && '-before_over')}
        data-testid="ItemDrop-before"
      />
      <span
        ref={afterRef}
        className={bem('-after', after.canDrop && '-after_active', after.isOver && '-after_over')}
        data-testid="ItemDrop-after"
      />
    </div>
  );
}
