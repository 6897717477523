import { createGraphiQLFetcher } from '@graphiql/toolkit';
import GraphiQL from 'graphiql';
import GraphiQLExplorer from 'graphiql-explorer';
import { buildClientSchema, getIntrospectionQuery } from 'graphql';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Flex from '@ui/Flex';
import Toggle from '@ui/Toggle';

import classes from './style.module.scss';

const GraphQLPlayground = ({ docExplorerOpen, editorTheme, url, user, ...props }) => {
  const fetcher = createGraphiQLFetcher({ url });
  const [schema, setSchema] = useState(null);
  const [currentQuery, setCurrentQuery] = useState(`{

}`);

  const [headers, setHeaders] = useState(() => {
    if (user && typeof user === 'object' && user !== null && user.graphql) {
      return JSON.stringify(
        {
          // Because we don't have OpenAPI-like security schemes to key off of we're instead allowing
          // folks to send us a `graphql` property in their user data, and if that's present we'll
          // prefill their users' GraphQL queries with a bearer token auth header.
          Authorization: `Bearer ${user.graphql}`,
        },
        null,
        2,
      );
    }

    return `{

}`;
  });

  useEffect(() => {
    // adding event listeners here for the tab behavior—
    // we don't have access to the components to do this the react way,
    // until we do a legit version of a graphql playground in the ✨future✨
    const tabs = document.querySelectorAll('#secondary-editor-title div');

    // set first tab as active
    tabs[0].classList.add('active');

    // add events
    tabs.forEach(tab => {
      tab.addEventListener('click', e => {
        tabs.forEach(i => i.classList.remove('active'));
        e.target.classList.add('active');
      });
    });
  }, []);

  useEffect(() => {
    const explorerFetcher = params =>
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      })
        .then(response => response.text())
        .then(responseBody => {
          try {
            return JSON.parse(responseBody);
          } catch (e) {
            if (e.name === 'AbortError') return e; // if the query has been aborted, do nothing
            return responseBody;
          }
        });

    explorerFetcher({ query: getIntrospectionQuery() })
      .then(result => {
        setSchema(buildClientSchema(result.data));
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [url]);

  return (
    <Flex className={classes.GraphQLPlayground} gap="0" justify="start" {...props}>
      <GraphiQL
        docExplorerOpen={docExplorerOpen}
        editorTheme={editorTheme}
        fetcher={fetcher}
        headers={headers}
        onEditHeaders={setHeaders}
        onEditQuery={setCurrentQuery}
        query={currentQuery}
        schema={schema}
      />
      <GraphiQLExplorer
        arrowClosed={<span className="icon icon-chevron-rightward" />}
        arrowOpen={<span className="icon icon-chevron-down" />}
        checkboxChecked={<Toggle checked type="checkbox" />}
        checkboxUnchecked={<Toggle type="checkbox" />}
        colors={{
          atom: 'var(--GraphQL-syntax-atom)', // Type
          attribute: 'var(--GraphQL-syntax-attribute)', // ArgumentName and ObjectFieldName
          builtin: 'var(--GraphQL-syntax-builtin)', // Boolean
          def: 'var(--GraphQL-syntax-def)', // OperationName, FragmentName
          keyword: 'var(--GraphQL-syntax-keyword)',
          number: 'var(--GraphQL-syntax-number)',
          property: 'var(--GraphQL-syntax-property)', // FieldName
          qualifier: 'var(--GraphQL-syntax-qualifier)', // FieldAlias
          string2: 'var(--GraphQL-syntax-string)', // Enum
          string: 'var(--GraphQL-syntax-string)',
          variable: 'var(--GraphQL-syntax-variable)',
        }}
        explorerIsOpen={docExplorerOpen}
        onEdit={setCurrentQuery}
        query={currentQuery}
        schema={schema}
        width="200px"
      />
    </Flex>
  );
};

GraphQLPlayground.propTypes = {
  docExplorerOpen: PropTypes.bool,
  editorTheme: PropTypes.string,
  query: PropTypes.string,
  url: PropTypes.string.isRequired,
  user: PropTypes.object,
};

GraphQLPlayground.defaultProps = {
  docExplorerOpen: true,
  editorTheme: 'material-palenight',
};

export default GraphQLPlayground;
