import React, { cloneElement, isValidElement } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

interface WithAutoSizerProps {
  children: React.ReactNode;
  /**
   * Height passed to child for initial render; useful for server-side rendering.
   * This value will be overridden with an accurate height after mounting.
   */
  defaultHeight: number;
  /**
   * If `true`, the children will be wrapped in an AutoSizer component.
   */
  isEnabled: boolean;
}

/**
 * A component that wraps its children in an AutoSizer component if auto-sizing is enabled.
 */
export default function WithAutoSizer({ children, defaultHeight, isEnabled }: WithAutoSizerProps) {
  const child = React.Children.only(children);
  return isEnabled && child && isValidElement(child) ? (
    <AutoSizer defaultHeight={defaultHeight} disableWidth>
      {autoSizerProps => cloneElement(child, { ...autoSizerProps })}
    </AutoSizer>
  ) : (
    <>{children}</>
  );
}
