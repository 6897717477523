import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import { PageNavItem } from '@ui/Dash/PageNav';

import styles from './Definitions.module.scss';

export function SidebarNavDefinitions() {
  const bem = useClassy(styles, 'SidebarNavDefinitions');
  const isReferenceDefinitionsRoute = useSuperHubStore(s => s.isReferenceDefinitionsRoute);

  return (
    <header className={bem('&')}>
      <PageNavItem
        active={isReferenceDefinitionsRoute}
        label="API Definitions"
        showActions={false}
        to="/update/reference"
        type="apiDefinitions"
      />
    </header>
  );
}
