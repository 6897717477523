import type { ReadCustomBlockGitCollectionType } from '@readme/api/src/mappings/customblock/types';
import type { ReadReferenceType } from '@readme/api/src/mappings/page/reference/types';
import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { PageClientSide, PageDocument } from '@readme/backend/models/page/types';
import type { $TSFixMe, HubResponseProps, SidebarPage, OAuthFeatureFlag } from '@readme/iso';

import { findOperation, sortHeaders } from '@readme/server-shared/metrics-oas'; // eslint-disable-line readme-internal/no-restricted-imports
import { EXPLORER_ENABLED, METRICS_ENABLED } from 'oas/extensions';
import { Operation } from 'oas/operation';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import type { ProjectContextValue, UserContextValue } from '@core/context';
import { ProjectContext, UserContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import useRdmdOpts from '@core/hooks/useRdmdOpts';
import useSentry from '@core/hooks/useSentry';
import useUserPermissions from '@core/hooks/useUserPermissions';
import {
  ConnectSuperHubDocumentToApi,
  ConnectSuperHubSidebarToApi,
  InitializeReferenceStore,
  InitializeSuperHubDocument,
  useProjectStore,
  useReferenceStore,
  useSuperHubStore,
} from '@core/store';
import { isAPIConfigPage } from '@core/store/SuperHub/Document/util';
import ScrollTop from '@core/utils/ScrollTop';

import AuthContainer from '@routes/Reference/components/AuthContainer';
import ErrorBoundary from '@routes/Reference/components/ErrorBoundary';
import GraphContainer from '@routes/Reference/components/GraphContainer';
import Playground, { PlaygroundSection } from '@routes/Reference/components/Playground';
import RequestContainer from '@routes/Reference/components/RequestContainer';
import ServerContainer from '@routes/Reference/components/ServerContainer';
import TableContainer from '@routes/Reference/components/TableContainer';
import HARContext, { EphemeralHARContext, MockHarContext } from '@routes/Reference/context/HARContext';
import useAuthInputs from '@routes/Reference/hooks/useAuthInputs';
import useOas from '@routes/Reference/hooks/useOas';
import UserAvatar from '@routes/Reference/Realtime/components/UserAvatar';
import classes from '@routes/Reference/style.module.scss';
import '@routes/Reference/style.scss';
import '@routes/SuperHub/Reference/style.scss';

import Callbacks from '@ui/API/Callbacks';
import Header from '@ui/API/Header';
import Response from '@ui/API/Response';
import ResponseSchemaPicker from '@ui/API/ResponseSchemaPicker';
import { createSchema } from '@ui/API/Schema';
import SectionHeader from '@ui/API/SectionHeader';
import Footer from '@ui/DocFooter/SuperhubDocFooter';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import LanguagePicker from '@ui/LanguagePicker';
import QuickNav from '@ui/QuickNav';
import transformSidebarDataForQuickNav from '@ui/QuickNav/inputDataTransformers/transformSidebarDataForQuickNav';
import RDMD, { TOC } from '@ui/RDMD';
import Sidebar from '@ui/Sidebar';
import Spinner from '@ui/Spinner';

import ConnectRoute from '../ConnectRoute';
import RedirectToEmptyParentChild from '../RedirectToEmptyParentChild';
import RedirectToSidebarFirstPage from '../RedirectToSidebarFirstPage';

import APIConfig from './APIConfig';
import EmptyReference from './Empty';
import styles from './index.module.scss';
import { normalizeGitDocToMongo, normalizeGitOasToMongo } from './util';

/**
 * Shape of route specific data for API reference pages.
 */
export interface ReferenceRouteProps {
  apiDefinitions?: never;
  customBlocks: ReadCustomBlockGitCollectionType['data'];
  document: ReadReferenceType['data'];
  maskErrorMessages: boolean;
  oasPublicUrl: string;
  oauth: OAuthFeatureFlag;
  sidebar: GitSidebarCategory[];
}

/**
 * This is a fork of `@routes/Reference/index` component that is used to render
 * `/reference/:slug` routes when `project.flags.superHub` is enabled.
 *
 * Because SuperHub uses APIv2 data, and the shape of that data is different
 * from internal Hub api data, we need to fork this component to handle the
 * differences.
 */
function Content({
  document: initialDocument,
  sidebar: initialSidebar,
  maskErrorMessages = true,
  oasPublicUrl,
  oauth = false,
  rdmd,
}: HubResponseProps<ReferenceRouteProps>) {
  const bem = useClassy(styles, 'SuperHubReference');
  const { path } = useRouteMatch();
  const { isLoggedIn } = useUserPermissions();
  const { user } = useContext(UserContext) as unknown as UserContextValue;
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { version } = useContext(VersionContext);
  const { clearEphemeralHAR, ephemeralHAR, setEphemeralHAR } = useContext(EphemeralHARContext);
  const { clearMockHars, shouldUseMockHars } = useContext(MockHarContext);

  const [selectedHar, setSelectedHar, servers] = useReferenceStore(s => [
    s.selectedHar,
    s.updateSelectedHar,
    s.form.schemaEditor.data.server,
  ]);
  const [
    enableApiSdkSnippets,
    enableDefaults,
    enableJsonEditor,
    enableRequestHistory,
    expandResponseExamples,
    expandResponseSchemas,
    projectSubdomain,
    hideTOC,
  ] = useProjectStore(s => [
    s.data.reference.api_sdk_snippets === 'enabled',
    s.data.reference.defaults === 'always_use',
    s.data.reference.json_editor === 'enabled',
    s.data.reference.request_history === 'enabled',
    s.data.reference.response_examples === 'expanded',
    s.data.reference.response_schemas === 'expanded',
    s.data.subdomain,
    s.data.appearance.table_of_contents === 'disabled',
  ]);

  const [showLogs, setShowLogs] = useState(false);

  /**
   * @todo we should add this to the APIv2 project mapper so we can read this
   * off the project store instead of ProjectContext
   */
  const metricsThumbsEnabled = project.metrics.thumbsEnabled;

  const [isLoading, isLoadingSidebar, document, sidebar] = useSuperHubStore(s => [
    // Only consider the document + sidebar to be "loading" if the initial SSR
    // data is different than what's currently in our store. This prevents an
    // unnecessary FOUC or unnecessary loading spinners on initial page load.
    s.document.isLoading && s.document.data !== initialDocument,
    s.sidebar.isLoading && s.sidebar.data !== initialSidebar,
    s.document.getReferencePageData(),
    s.sidebar.data,
  ]);

  const oasDefinition = document ? normalizeGitOasToMongo(document) : null;
  const legacyDoc = useMemo(() => normalizeGitDocToMongo(document ?? undefined), [document]);

  const { oas, operation, dereferencingError, isLoading: isOasDereferencing } = useOas(oasDefinition, legacyDoc);

  const { inputRef, onAuthError } = useAuthInputs();

  useEffect(() => {
    // Reset the HAR file in state when a new operation is selected.
    clearMockHars();
    clearEphemeralHAR();
  }, [operation]); // eslint-disable-line react-hooks/exhaustive-deps

  const rdmdOpts = useRdmdOpts();

  const isEndpoint = document?.type === 'endpoint';

  const isWebhook = (document as $TSFixMe)?.type === 'webhook';

  /**
   * A normalized URL that we'll pass through to our Metrics service
   * This URL allows us to filter API logs for a specific endpoint/resource
   *
   * Returns a value similar to https://{region}.server.com/path/${resource}
   * In metrics, we'll use this value to query our database with wildcards via
   * fuzzy matching in place of each {curly brace} value.
   *
   * * @todo  Since `GraphContainer`, `TableContainer` and `APIHeader` all use this same data maybe it should be in a context?
   */
  const metricsFilterUrl = useMemo(() => {
    if (operation === null || !isEndpoint) {
      return null;
    }

    return `${oas.url(servers?.selected, {})}${operation.path}`;
    // We don't want to do a reference comparison for oas, we only care if the `id` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEndpoint, oas?.api?._id, servers, operation]);

  const preparedQuickNavData = useMemo(() => {
    try {
      return transformSidebarDataForQuickNav(sidebar);
    } catch {
      return null;
    }
  }, [sidebar]);

  const header = useMemo(
    () => (
      <div className={classes['Main-QuickNav-container']}>
        <QuickNav destinations={preparedQuickNavData} modalTarget={'#QuickNav-modal-root'} />
      </div>
    ),
    [preparedQuickNavData],
  );

  // Are try-it-now requests enabled in the explorer via OAS
  const requestsEnabled = useMemo(
    () => !!oas?.getExtension(EXPLORER_ENABLED, operation) || false,
    // We don't want to do a reference comparison for oas, we only care if the `id` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oas.api?._id, operation],
  );

  // If they've disabled try-it-now in the explorer, but they've got the module enabled
  // We should check to see if they're actually using it
  const { data: usageResponse } = useMetricsAPI<{ sdk: { thirtyDay: number } }>('requests/usage', !requestsEnabled);

  useEffect(() => {
    // We should ever show logs on an operation if Metrics is explicitly or implicitly enabled on
    // it and if interactivity is enabled or it's clear that Metrics is in use from usage reports.
    if (!oas.getExtension(METRICS_ENABLED, operation)) {
      setShowLogs(false);
      return;
    }

    setShowLogs(requestsEnabled || (usageResponse?.sdk?.thirtyDay || 0) > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oas?.api?._id, operation, requestsEnabled, usageResponse]);

  // Is My Requests page enabled (Dev Dash in Hub)
  const myRequestsEnabled = useMemo(() => {
    return !!sidebar?.[0]?.pages?.find(({ slug }) => slug === 'my-requests') || false;
  }, [sidebar]);

  const isAPIConfig = useMemo(() => isAPIConfigPage(document), [document]);

  // Custom icon render for static pages like API Config pages
  const customIconRender = useCallback(
    (page: SidebarPage) => {
      // In git-backed API Config (`api_config`) pages, we won't have `icon` property in the page object
      // so we'll check for `api_config` property to determine the icon
      if ('api_config' in page) {
        switch (page.api_config) {
          case 'getting-started':
            return <Icon aria-hidden="true" name="book" />;
          case 'authentication':
            return <Icon aria-hidden="true" name="key" />;
          case 'my-requests':
            return isLoggedIn ? <UserAvatar /> : <Icon aria-hidden="true" name="gauge-circle" />;
          default:
            return null;
        }
      }

      const isYourRequests = (page as PageDocument)?.pageType === 'RealtimePage' && page?.title === 'My Requests';
      // "My Requests" page in sidebar has special icon handling
      return isLoggedIn && isYourRequests ? <UserAvatar /> : null;
    },
    [isLoggedIn],
  );

  const Params = useMemo(() => {
    // This needs to be memoized! If it isn't we'll recreate the Params component everytime a state change happens,
    // which will cause the user to lose focus on their input.
    return createSchema(oas, operation);
    // We don't want to do a reference comparison for oas, we only care if the `id` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oas?.api?._id, operation]);

  const handleSelectLog = useCallback(
    ({ id, response }) => {
      // Always clear the har shown in the playground when a log is de-selected
      if (id == null) {
        setEphemeralHAR(null);
      }

      if (id == null || response?.log == null) {
        setSelectedHar(null);
        return;
      } else if (isOasDereferencing) {
        return;
      }

      const { log, sharedMetric } = response;
      const { logOperation } = findOperation(log, [oas]) as $TSFixMe;

      // If an operation is not found for this log so we silently ignore it
      if (logOperation == null) {
        return;
      }

      const op = new Operation(oas?.api, logOperation.url.path, logOperation.url.method, logOperation.operation);
      // This function ensures the request's headers only includes those that are those present in the OAS
      const nextLog = sortHeaders(log, op);
      const { createdAt, group } = log;

      setSelectedHar({ id, isMock: Boolean(nextLog.isMock), createdAt, group, sharedMetric, ...nextLog.request });
    },
    // We don't want to do a reference comparison for oas, we only care if dereferencing has finished or the
    // internal `id` has changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOasDereferencing, oas?.api?._id],
  );

  const onError = useSentry('reference');

  /**
   * Error Boundry
   */
  if (dereferencingError) {
    // We're passing in `error.message` into the error boundary because it expects a child node to be present. It's fine
    // though as since we're also passing an `error` prop we'll default to our standard error handling instead of
    // rendering out that error message.
    return (
      <ErrorBoundary
        appContext="explorer"
        error={dereferencingError}
        maskErrorMessages={maskErrorMessages}
        onError={onError}
      >
        {(dereferencingError as $TSFixMe).message}
      </ErrorBoundary>
    );
  } else if (operation === null && isEndpoint) {
    if (isLoading) {
      // Don't return an error before the route has actually loaded!
      return <main className="loading" id="Reference" />;
    }

    return (
      <ErrorBoundary
        appContext="explorer"
        error={new Error('Operation unable to be located within the supplied OAS.')}
        maskErrorMessages={maskErrorMessages}
        onError={onError}
      >
        We’re sorry, we weren’t able to render the docs for this API operation.
      </ErrorBoundary>
    );
  }

  // When no API definitions yet exist, force the user into our creator flow.
  if (!isLoading && !isLoadingSidebar && !document) {
    return <EmptyReference />;
  }

  // If the document is marked as not renderable, we can assume there is an MDX syntax error.
  const hasMdxError = !document?.renderable?.status;
  const dehydratedToc = rdmd && rdmd.dehydrated && 'toc' in rdmd.dehydrated ? rdmd.dehydrated.toc : undefined;

  return (
    <ErrorBoundary appContext="explorer" maskErrorMessages={maskErrorMessages} onError={onError}>
      <InitializeReferenceStore
        apiDefinition={oas}
        maxLanguages={isAPIConfig ? 7 : 5}
        operation={operation}
        simpleMode={enableApiSdkSnippets}
      >
        <main
          className={`rm-ReferenceMain rm-ReferenceMain-SuperHub rm-Container rm-Container_flex ${isEndpoint ? '' : 'rm-basic-page'} ${
            isLoading || isLoadingSidebar ? 'rm-ReferenceMain_loading' : ''
          }`}
          id="Explorer"
        >
          {!isLoadingSidebar && (
            <Sidebar
              activeDoc={document?.slug}
              categories={sidebar}
              customIconRender={customIconRender}
              header={preparedQuickNavData ? header : undefined}
              id="reference-sidebar"
              pathRoot="reference"
            />
          )}
          <Switch>
            <Route path={`${path}/:slug?`}>
              {/* API Config pages (formerly Realtime) have their own rendering logic */}
              {isAPIConfig && document?.api_config ? (
                <APIConfig
                  apiConfig={document.api_config}
                  doc={legacyDoc as unknown as PageClientSide}
                  hasOasDefinition={!!oasDefinition}
                  inputRef={inputRef}
                  myRequestsEnabled={myRequestsEnabled}
                  oas={oas}
                  oasPublicUrl={oasPublicUrl}
                  oauth={oauth}
                  onError={onAuthError}
                  operation={operation}
                  operationParams={
                    <Params
                      alwaysUseDefaults={enableDefaults}
                      globalDefaults={user?.parameters}
                      oas={oas}
                      operation={operation}
                    />
                  }
                  requestsEnabled={requestsEnabled}
                  sidebar={sidebar}
                />
              ) : (
                <Flex className={bem('-article-container')} grow={1}>
                  <article className="rm-Article" id="content">
                    {!!isLoading && <Spinner kind="overlay" size="xxl" />}
                    {!!legacyDoc && (
                      <Header
                        doc={legacyDoc}
                        isWebhook={isWebhook}
                        oas={oas}
                        operation={operation}
                        servers={servers}
                        sidebar={sidebar}
                      />
                    )}

                    {/**
                     * @todo Tutorials are not yet supported in git backed projects
                     * {!!doc && !!doc.tutorials && doc.tutorials.length > 0 && (
                     *  <RecipeContainer tutorials={doc.tutorials} />
                     * )}
                     */}
                    {!!enableRequestHistory && !!isEndpoint && !!showLogs && (
                      <ErrorBoundary appContext="explorer" maskErrorMessages={maskErrorMessages} onError={onError}>
                        <div className="Reference-section">
                          <SectionHeader
                            heading={
                              <Flex align="center" gap="xs" justify="start">
                                {!isLoggedIn ? (
                                  <>
                                    <Icon aria-label="Key" name="key" />
                                    Log in to see full request history
                                  </>
                                ) : (
                                  'Recent Requests'
                                )}
                              </Flex>
                            }
                          />

                          <TableContainer
                            ephemeralHAR={ephemeralHAR as $TSFixMe}
                            footer={
                              !shouldUseMockHars && (
                                <GraphContainer
                                  ephemeralHAR={ephemeralHAR as $TSFixMe}
                                  isLoggedIn={isLoggedIn}
                                  method={operation.method}
                                  myRequestsEnabled={myRequestsEnabled}
                                  url={metricsFilterUrl || ''}
                                />
                              )
                            }
                            onSelectLog={handleSelectLog}
                            operation={operation}
                            url={metricsFilterUrl || ''}
                          />
                        </div>
                      </ErrorBoundary>
                    )}

                    {!!document?.content.body && (
                      <RDMD
                        key={document.slug}
                        body={document.content.body}
                        className="content-body"
                        dehydrated={rdmd?.dehydrated?.body}
                        mdx={!hasMdxError}
                        opts={rdmdOpts}
                      />
                    )}

                    {(!!isEndpoint || !!isWebhook) && (
                      <>
                        <Params
                          alwaysUseDefaults={enableDefaults}
                          globalDefaults={user?.parameters}
                          isWebhook={isWebhook}
                          oas={oas}
                          // onSubmit={() => {}} // @todo This should be wired up into the same executor as clicking the "Try It" button.
                          operation={operation}
                        />
                        <ResponseSchemaPicker
                          defaultExpandResponseSchema={expandResponseSchemas}
                          oas={oas}
                          operation={operation}
                        />
                        <div className="ModalWrapper" id="response-schema-modal-target" />
                      </>
                    )}

                    {!!operation?.hasCallbacks() && (
                      <>
                        <Callbacks oas={oas} operation={operation} />
                        <div className="ModalWrapper" id="callback-response-schema-modal-target" />
                      </>
                    )}

                    {!(isLoading || isLoadingSidebar || isOasDereferencing) && (
                      <div className={classes['Footer-desktop']}>
                        <Footer
                          document={document ?? undefined}
                          metricsThumbsEnabled={metricsThumbsEnabled}
                          projectSubdomain={projectSubdomain}
                          sidebar={sidebar}
                          version={version}
                        />
                      </div>
                    )}
                  </article>
                  {(!!isEndpoint || !!isWebhook) && (
                    <Playground id="ReferencePlayground">
                      {!!isEndpoint && (
                        <>
                          <PlaygroundSection heading="Language">
                            <LanguagePicker />
                          </PlaygroundSection>
                          <PlaygroundSection>
                            <AuthContainer
                              apiDefinition={oas}
                              customLoginEnabled={!!project.oauth_url}
                              inputRef={inputRef}
                              oauth={oauth}
                              operation={operation}
                              setSelectedHar={setSelectedHar as $TSFixMe}
                            />
                          </PlaygroundSection>
                          {/*
                          Only show the servers component if one of these conditions are true:
                          - there is more than one server in the servers[] array
                          - the server URL has a variable (so needs editing)
                          @todo this should probably be refactored into Oas.hasEditableServer() or something
                        */}
                          {((oas?.api?.servers || [{ url: 'https://example.com' }]).length > 1 ||
                            oas.splitUrl(servers?.selected).filter(({ type }) => type === 'variable').length > 0) && (
                            <PlaygroundSection heading="URL">
                              <ServerContainer oas={oas} operation={operation} />
                            </PlaygroundSection>
                          )}
                        </>
                      )}
                      <PlaygroundSection sticky>
                        <RequestContainer
                          allowApiExplorerJsonEditor={enableJsonEditor}
                          apiDefinition={oas}
                          har={selectedHar as $TSFixMe}
                          isWebhook={isWebhook}
                          onError={onAuthError}
                          operation={operation}
                          requestsEnabled={requestsEnabled}
                          setResponseHAR={setEphemeralHAR}
                          url={oasPublicUrl}
                        />
                        {!!isEndpoint && (
                          <Response
                            apiDefinition={oas}
                            defaultExpandResponseExample={expandResponseExamples}
                            har={selectedHar || ephemeralHAR || null}
                            onExampleRemove={clearEphemeralHAR}
                            operation={operation}
                            requestsEnabled={requestsEnabled}
                            setSelectedHar={setSelectedHar}
                          />
                        )}
                      </PlaygroundSection>
                    </Playground>
                  )}

                  {!(isLoading || isOasDereferencing) && (
                    <div className={classes['Footer-mobile']}>
                      <Footer
                        document={document ?? undefined}
                        metricsThumbsEnabled={metricsThumbsEnabled}
                        projectSubdomain={projectSubdomain}
                        sidebar={sidebar}
                        version={version}
                      />
                    </div>
                  )}
                  {!hideTOC && document?.type === 'basic' && (
                    <section className="content-toc grid-25">
                      <TOC
                        body={document?.content.body}
                        dehydrated={dehydratedToc}
                        mdx={!hasMdxError}
                        opts={rdmdOpts}
                      />
                    </section>
                  )}
                </Flex>
              )}
            </Route>
          </Switch>

          {/**
           * Response headers modal root lives outside <Response/> component to escape z-index trapping from
           * a position:sticky; wrapper in the parent <PlaygroundSection/> component
           */}
          <div className="ModalWrapper" id="response-headers-modal-root" />

          <div className="ModalWrapper" id="tutorialmodal-root"></div>
          <div className="ModalWrapper QuickNav-modal QuickNav-modal-desktop" id="QuickNav-modal-root" />
          <div className="ModalWrapper QuickNav-modal QuickNav-modal-mobile" id="QuickNav-mobile-modal-root" />
        </main>
      </InitializeReferenceStore>
    </ErrorBoundary>
  );
}

export default function SuperHubReference({ ...props }: HubResponseProps<ReferenceRouteProps>) {
  const { document: initialDocument, customBlocks: initialCustomBlocks, sidebar: initialSidebar } = props;

  return (
    <ConnectSuperHubSidebarToApi initialSidebar={initialSidebar}>
      <RedirectToSidebarFirstPage>
        <RedirectToEmptyParentChild>
          <InitializeSuperHubDocument customBlocks={initialCustomBlocks} document={initialDocument}>
            <ConnectSuperHubDocumentToApi>
              <HARContext>
                <ScrollTop smooth />
                <ConnectRoute next={Content} props={props} />
              </HARContext>
            </ConnectSuperHubDocumentToApi>
          </InitializeSuperHubDocument>
        </RedirectToEmptyParentChild>
      </RedirectToSidebarFirstPage>
    </ConnectSuperHubSidebarToApi>
  );
}
