import type { ItemDragEndResult, ItemDropProps, ParentDropProps } from '../DragDrop';
import type { PageNavItemProps } from '../Item';
import type { PageNavMetaControlsAction, PageNavMetaControlsProps } from '../MetaControls';
import type { ProjectDocument } from '@readme/backend/models/project/types';

import React, { useCallback, useMemo, useRef, useState, useEffect, createContext } from 'react';

import useClassy from '@core/hooks/useClassy';
import useDragAndDropContext from '@core/hooks/useDragAndDropContext';

import Collapsible from '@ui/Collapsible';
import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Icon from '@ui/Icon';
import Input from '@ui/Input';

import { PageNavItem } from '..';
import { useIsDragItemHovered, ItemDrop, ParentDrop, useItemDrag } from '../DragDrop';
import PageNavMetaControls from '../MetaControls';
import Tooltip from '../Tooltip';

import styles from './index.module.scss';

/**
 * Contains information about each category that allows all items nested within
 * to be aware of parent category information.
 */
export const PageNavCategoryContext = createContext<{
  id: PageNavCategoryProps['id'];
  label: PageNavCategoryProps['label'];
}>({ id: undefined, label: '' });
PageNavCategoryContext.displayName = 'PageNavCategoryContext';

/**
 * Contains input validation results from the "edit" handler. If input is valid,
 * then validation error is falsy. Otherwise, validation error is truthy and
 * contains a description about the invalid result.
 */
type onEditValidationError = string;

export interface PageNavCategoryProps {
  children?: React.ReactNode;
  className?: string;

  /**
   * Optional boolean to control collapsibility on category (via pointer-events), defaults to true
   */
  collapsible?: boolean;

  /**
   * Overrides the default contextual actions for this category.
   */
  configureActions?: PageNavMetaControlsProps['configureActions'];

  /**
   * Render a number counter of how many child items are contained.
   */
  counter?: boolean;

  /**
   * Timestamp that this item was created. When provided, timestamp is
   * displayed in the context menu
   */
  created?: Date | string;

  /**
   * Boolean indicating whether the category is disabled or not
   */
  disabled?: boolean;

  /**
   * Boolean indicating whether the category is editable or not
   */
  editable?: boolean;

  /**
   * Unique ID used by children to identify which parent it belongs to.
   */
  id?: string;

  /**
   * Displayed label
   */
  label: string;

  /**
   * Called when this category's context actions are acted on (e.g. add, delete,
   * duplicate, etc).
   */
  onAction?: ({ id, action }: { action: PageNavMetaControlsAction; id: PageNavCategoryProps['id'] }) => void;

  /**
   * Called when category is being created or renamed. Return a `string` with an
   * error message to trigger a validation error on the input field.
   */
  onEdit?: ({
    id,
    isValid,
    label,
  }: {
    id: PageNavCategoryProps['id'];
    /**
     * Indicates that the edit operation is valid and should proceed. Valid
     * means that value has changed, is not empty and ESC key was not pressed.
     */
    isValid?: boolean;
    label: string;
  }) => onEditValidationError | void;

  /**
   * Called when category is moved to another position.
   */
  onMove?: (result: ItemDragEndResult) => void;

  /**
   * Called when the category is expanded/collapsed
   */
  onToggle?: ({ id, isOpen }: { id: PageNavCategoryProps['id']; isOpen: boolean }) => void;

  /**
   * Derived project plan from several fields including the override, which is used to determine if certain actions are allowed.
   */
  plan?: ProjectDocument['plan_trial'];

  /**
   * Zero-based index of this category's position in relation to other siblings.
   * When left unassigned, position is auto-assigned and incremented by
   * `PageNav` for each `PageNavCategory` rendered within. These positions are
   * used and reported by drag move operations. To exclude `PageNavCategory`
   * from the positioning sequence, assign a value of `-1`.
   */
  position?: number;

  /**
   * Allows dragging, dropping and contextual actions like Add, Rename, Delete,
   * etc. to be performed. Default is `true`. Disable this to render a fixed
   * category that cannot be dragged, dropped onto or removed.
   */
  showActions?: boolean;

  /**
   * Start in an opened state.
   */
  startOpened?: boolean;

  /**
   * Number of child pages that are nested inside this category. Consumers are
   * responsible for correctly setting this value to correctly display
   * collapsible containers and count labels in addition to other behaviors.
   */
  totalChildPages?: number;
}

/**
 * Categories are used to group together a collection of [`PageNavItem`](/#/Components/Dash/PageNavItem) elements.
 * It renders a label block that is actionable and toggles the group in an
 * opened or closed state.
 */
const PageNavCategory = React.memo(function PageNavCategory({
  children,
  className,
  counter = true,
  created,
  collapsible = true,
  configureActions = {},
  disabled = false,
  editable = false,
  id,
  label: propLabel,
  onAction,
  onEdit,
  onMove,
  onToggle,
  plan,
  position = 0,
  showActions = true,
  startOpened = false,
  totalChildPages = 0,
  ...elementProps
}: PageNavCategoryProps) {
  const bem = useClassy(styles, 'PageNavCategory');
  const { id: dndProviderId } = useDragAndDropContext();

  const rootRef = useRef<HTMLElement | null>(null);
  const contentRef = useRef<HTMLElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [opened, setOpened] = useState(startOpened);
  const [showControls, setShowControls] = useState(false);
  const [label, setLabel] = useState(propLabel);
  const [inputError, setInputError] = useState<string>();

  /**
   * Indicates whether this category contains items that are endpoints.
   */
  const containsEndpoints = useMemo(
    () =>
      React.Children.toArray(children).some(child => {
        if (!React.isValidElement(child) || child.type !== PageNavItem) {
          return false;
        }

        type PageNavItemElement = React.ReactElement<PageNavItemProps>;
        const item = child as PageNavItemElement;
        return (
          !!item.props.endpoint ||
          React.Children.toArray(item.props.children).some(
            itemChild => !!(itemChild as PageNavItemElement)?.props?.endpoint,
          )
        );
      }),
    [children],
  );

  /**
   * Contains additional configuration for the contextual meta actions. For
   * example, when this category contains endpoints, we need to disable the
   * "move-toggle" action and display a tooltip explaining why.
   */
  const configureMetaActions = useMemo(() => {
    const config: PageNavMetaControlsProps['configureActions'] = {
      add: {
        description: 'Add page',
      },
      'move-toggle': containsEndpoints
        ? {
            description: 'Cannot move categories containing pages with API endpoints.',
            disabled: true,
          }
        : plan === 'freelaunch'
          ? {
              description: 'Upgrade to move categories to Guides.',
              disabled: true,
            }
          : undefined,
      // Allow consumers to override the default configuration
      ...configureActions,
    };
    return config;
  }, [configureActions, containsEndpoints, plan]);

  const handleMetaControlsAction = useCallback<NonNullable<PageNavMetaControlsProps['onAction']>>(
    name => {
      onAction?.({ id, action: name });
    },
    [id, onAction],
  );

  // Configure this component to be draggable.
  const { isDragging } = useItemDrag({
    canDrag: () => !!id && showActions,
    elementRef: contentRef,
    type: 'category',
    item: {
      categoryId: undefined,
      dndProviderId,
      id,
      hasChildren: !!totalChildPages,
      parentId: undefined,
      position,
      meta: {
        label,
      },
    },
    end: result => {
      onMove?.(result);
    },
  });

  // Called when items are dropped onto this category. Move item into the first
  // child position inside this category.
  const handleParentDrop = useCallback<ParentDropProps['drop']>(() => {
    return {
      categoryId: id,
      id: id || '',
      parentId: id,
      position: 0,
    };
  }, [id]);

  // Called when categories are dropped before or after this category (i.e.
  // reordered). Simply return the current position of this category.
  const handleItemDrop = useCallback<ItemDropProps['drop']>(() => {
    return {
      categoryId: undefined,
      id: id || '',
      parentId: undefined,
      position,
    };
  }, [id, position]);

  useEffect(() => {
    if (editable) {
      inputRef?.current?.focus();
      inputRef?.current?.scrollIntoView({ block: 'nearest' });
    } else {
      setInputError(undefined);
    }
  }, [editable]);

  useEffect(() => {
    setLabel(propLabel);
  }, [propLabel]);

  const { isDragItemHovered, isCategoryDragging } = useIsDragItemHovered({
    accept: ['category', 'item'],
    disabled: !showActions,
    elementRef: contentRef,
    id,
    collect: monitor => ({
      isCategoryDragging: monitor.getItemType() === 'category',
    }),
  });

  return (
    <PageNavCategoryContext.Provider value={useMemo(() => ({ id, label }), [id, label])}>
      <Flex
        ref={rootRef}
        align="stretch"
        className={bem('&', className, isDragging && '_dragging')}
        gap="0"
        justify="start"
        layout="col"
      >
        <Flex
          ref={contentRef}
          align="center"
          aria-expanded={opened}
          aria-label={label}
          aria-level="1"
          aria-selected="false"
          className={bem(
            '-content',
            (isDragging || isDragItemHovered || isCategoryDragging || editable) && '-content_nohover',
            opened && '-content_opened',
            showControls && !editable && '-content_show-controls',
            disabled && '-content_disabled',
            !collapsible && '-content_disabled-collapse',
          )}
          data-testid="page-nav-category-content"
          gap="0"
          justify="start"
          role="treeitem"
          {...elementProps}
        >
          <Icon className={bem('-content-chevron')} color="gray60" name="chevron-right" size="md" />
          {
            // NOTE(Optimization): We must render our drop targets only as needed,
            // mainly when a dragged item hovers over another item. Otherwise,
            // every item (potentially many) will react to the drag operation,
            // which is expensive and will clog up rendering.
            !!isDragItemHovered && (
              <>
                <ParentDrop
                  accept="item"
                  canDrop={() => !!id && showActions}
                  drop={handleParentDrop}
                  id={id}
                  setToggle={setOpened}
                />
                <ItemDrop accept="category" canDrop={() => !!id && showActions} drop={handleItemDrop} id={id} />
              </>
            )
          }
          <Flex
            ref={buttonRef}
            align="center"
            className={bem('-toggle')}
            data-testid="PageNavCategory-toggle"
            draggable={false}
            gap="sm"
            onBlur={() => setShowControls(false)}
            onClick={() => {
              // Prevent toggling the collapsible child container when editable.
              if (editable) return;
              setOpened(!opened);
              onToggle?.({ id, isOpen: !opened });
            }}
            onFocus={() => setShowControls(true)}
            tag="button"
          >
            <Flex align="center" className={bem('-label')} gap="sm" justify="start" tag="span">
              {editable ? (
                <FormGroup className={bem('-input-container')} errorMessage={inputError} size="sm">
                  <Input
                    ref={inputRef}
                    className={bem('-input', inputError && '-input_invalid')}
                    // Make blur event act like a form submit and save changes.
                    onBlur={e => {
                      if (!label) {
                        setLabel(propLabel);
                      }
                      const error = onEdit?.({ id, label, isValid: !!label && label !== propLabel });
                      setInputError(error ?? undefined);
                      e.currentTarget.select();
                    }}
                    onChange={e => setLabel(e.target.value)}
                    onKeyDown={e => {
                      switch (e.key) {
                        case 'Escape':
                          setLabel(propLabel);
                          onEdit?.({ id, label, isValid: false });
                          break;
                        case 'Enter': {
                          // Forward to blur event handler to submit.
                          e.currentTarget.blur();
                          break;
                        }
                        default:
                          break;
                      }
                    }}
                    placeholder="New Category"
                    size="xs"
                    value={label}
                  />
                </FormGroup>
              ) : (
                <>
                  <Tooltip content={label.toUpperCase()} placement="top-start">
                    <span className={bem('-truncated-label')}>{label}</span>
                  </Tooltip>
                  {!!counter && (
                    <span className={bem('-count-label')} data-testid="PageNavCategory-count-label">
                      {totalChildPages}
                    </span>
                  )}
                </>
              )}
            </Flex>
          </Flex>
          {!!showActions && (
            <PageNavMetaControls
              className={bem('-meta-controls')}
              configureActions={configureMetaActions}
              contextMenuRef={buttonRef}
              created={created}
              onAction={handleMetaControlsAction}
              onBlur={() => setShowControls(false)}
              onFocus={() => setShowControls(true)}
              type="category"
            />
          )}
        </Flex>
        {!!totalChildPages && !!children && (
          <Collapsible
            className={bem('-children')}
            opened={
              // Allow drag operations to auto-collapse child container only when
              // "actions" are enabled.
              showActions ? !isCategoryDragging && opened : opened
            }
          >
            {children}
          </Collapsible>
        )}
      </Flex>
    </PageNavCategoryContext.Provider>
  );
});

export default PageNavCategory;
