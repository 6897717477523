import React from 'react';

import { useSuperHubStore } from '@core/store';

import EmptyState from '@routes/SuperHub/components/EmptyState';

/**
 * @todo render different empty states for admin vs user.
 */
export default function EmptyChangelog() {
  const isSuperHubAdmin = useSuperHubStore(s => s.isSuperHubAdmin);

  return <EmptyState action={isSuperHubAdmin ? '/create/changelog' : undefined} section="changelog" />;
}
