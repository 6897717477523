import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import Skeleton from '@ui/Skeleton';

import styles from '../style.module.scss';

export const SKELETON_WIDTH = '95px';

const ParameterGroupSkeleton = ({ className }) => {
  const bem = useClassy(styles, 'Parameter');

  return (
    <section className={bem('&', '_skeleton', className)}>
      <Flex justify="space-between" style={{ width: '100%' }}>
        <Flex gap="sm" layout="col" style={{ width: '100%' }}>
          <Flex gap="sm" justify="start" style={{ width: '100%' }}>
            <Skeleton width="40px" />
            <Skeleton width={SKELETON_WIDTH} />
            <Skeleton width={SKELETON_WIDTH} />
          </Flex>
          <Skeleton width={SKELETON_WIDTH} />
        </Flex>
        <div className={bem('_skeleton-value')}></div>
      </Flex>
    </section>
  );
};

export default ParameterGroupSkeleton;
