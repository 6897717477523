import type { HttpMethods } from 'oas/types';

import { HTTP_METHOD, type HTTPMethod } from '@readme/iso';
import React, { useEffect, useMemo, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useAPIDesignerStore } from '@core/store/APIDesigner';

import APIMethod from '@ui/API/Method';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Tooltip from '@ui/Tooltip';

import useApiDesignerValidation from '../hooks/useApiDesignerValidation';

import classes from './style.module.scss';

const httpMethods: HttpMethods[] = [
  HTTP_METHOD.GET,
  HTTP_METHOD.POST,
  HTTP_METHOD.PUT,
  HTTP_METHOD.PATCH,
  HTTP_METHOD.DELETE,
  HTTP_METHOD.HEAD,
  HTTP_METHOD.OPTIONS,
  HTTP_METHOD.TRACE,
];

const duplicateOperationErrorMessage = 'Method and path combination already exists.';

const OperationEditor = ({
  disabled = false,
  pathInputRef,
}: {
  disabled?: boolean;
  pathInputRef?: React.RefObject<HTMLInputElement>;
}) => {
  const bem = useClassy(classes, 'OperationEditor');

  const [setMethod, setPath, path, method, schema] = useAPIDesignerStore(s => [
    s.setMethod,
    s.setPath,
    s.apiObject?.path,
    s.apiObject?.method,
    s.apiObject?.schema,
  ]);

  const revalidationRef = useRef<((field: 'method' | 'path') => void) | null>(null);

  const {
    error: pathError,
    checkValidity: checkPathValidity,
    field: pathFieldProps,
  } = useApiDesignerValidation<string>({
    name: 'path',
    validate: {
      required: 'Path is required.',
      beforeChange: (nextPath, formState) => {
        // Create a copy of schema.paths and remove the current path if it exists
        const currentPaths = path ? { ...formState?.schema.paths, [path]: undefined } : { ...formState?.schema.paths };

        // Check if the path is already in use for the current method
        if (formState?.method && !!currentPaths?.[nextPath]?.[formState?.method]) {
          return duplicateOperationErrorMessage;
        }
        return true;
      },
    },
    value: path || '',
    handleChange: val => {
      setPath(val);
      // We need to revalidate the method field if the path changes
      // in case the method field as an error based on the current path that needs to be cleared
      revalidationRef.current?.('method');
    },
  });

  const {
    error: methodError,
    checkValidity: checkMethodValidity,
    field: methodFieldProps,
  } = useApiDesignerValidation<HttpMethods>({
    name: 'method',
    validate: {
      beforeChange: (nextMethod, formState) => {
        // Check if the method is already in use for the current path
        if (formState?.path && !!formState?.schema?.paths[formState?.path]?.[nextMethod]) {
          return duplicateOperationErrorMessage;
        }
        return true;
      },
    },
    value: method || 'get',
    handleChange: val => {
      setMethod(val);
      // We need to revalidate the path field if the method changes
      // in case the path field as an error based on the current method that needs to be cleared
      revalidationRef.current?.('path');
    },
  });

  // Because the path and method validation rules are dependent on each other, we need to keep track of
  // each field's error state and validation functions so we can revalidate the other field if it has an error.
  useEffect(() => {
    revalidationRef.current = (field: 'method' | 'path') => {
      if (field === 'method' && !!methodError) {
        checkMethodValidity();
      } else if (field === 'path' && !!pathError) {
        checkPathValidity();
      }
    };
  }, [checkPathValidity, checkMethodValidity, methodError, pathError]);

  // Create a list of method options that are valid for the current path
  // based on the current path and method field values
  const methodOptions = useMemo(() => {
    return httpMethods.map(m => {
      const currentPath = pathFieldProps.value;

      let isValid = currentPath && schema?.paths?.[currentPath]?.[m] === undefined;
      // If the path and method are already in use, we want to allow the user to select the method
      if (path && method && schema?.paths?.[currentPath]?.[method] && method === m) isValid = true;

      return {
        value: m,
        isDisabled: !isValid,
      };
    });
  }, [pathFieldProps.value, schema?.paths, path, method]);

  if (!schema || !schema.paths) {
    // There isn't anything in the OAS document yet
    // Do we need to handle this? How should we?
    return null;
  }

  return (
    <Flex gap="xs" layout="col">
      <Flex align="start" className={bem('&')} gap="xs" justify="start">
        <label className={bem('-method')}>
          <APIMethod
            className={bem('-method-pill')}
            suffix={!disabled && <Icon className={bem('-icon')} name="chevron-down" />}
            type={method as HTTPMethod}
          />
          <select className={bem('-method-select')} disabled={disabled} name="path_method" {...methodFieldProps}>
            {methodOptions.map(({ value, isDisabled }) => {
              return (
                <option key={value} disabled={isDisabled} value={value}>
                  {value.toUpperCase()}
                </option>
              );
            })}
          </select>
        </label>

        <Flex className={bem('-path')} gap="0">
          <span>{schema.servers?.[0]?.url}</span>
          <Flex className={bem('-path-field')} gap="0" layout="col">
            <div className={bem('-inputSizer')}>
              <Tooltip
                asTitle
                content={
                  <>
                    Add path parameters in the URL. Format <code>{'{param}'}</code>
                  </>
                }
                onShow={instance => {
                  setTimeout(() => {
                    instance.hide();
                  }, 2000);
                }}
                trigger="focus"
              >
                <input
                  className={bem('-input', !!pathError && '-input_error')}
                  disabled={disabled}
                  {...pathFieldProps}
                  ref={pathInputRef}
                />
              </Tooltip>
              <span aria-hidden="true" className={bem('-inputSizer-clone')}>
                {pathFieldProps.value}
              </span>
            </div>
          </Flex>
        </Flex>
      </Flex>

      {pathError ? (
        <span className={bem('-error')}>{pathError}</span>
      ) : methodError ? (
        <span className={bem('-error')}>{methodError}</span>
      ) : null}
    </Flex>
  );
};

export default OperationEditor;
