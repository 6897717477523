import React from 'react';

import useUniqueId from '@core/hooks/useUniqueId';

import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';
import Toggle from '@ui/Toggle';

import HeaderInput from '../components/HeaderInput';
import { useSuperHubEditorFormContext } from '../Context';
import Layout, { LayoutBody, LayoutHeader, LayoutTitle } from '../Layout';
import useFormSetFocus from '../useFormSetFocus';

/**
 * Form fields specific to the `link` page type.
 */
export default function LinkPage() {
  const uid = useUniqueId('SuperHubEditorFormLinkPage');
  const { control } = useSuperHubEditorFormContext();

  useFormSetFocus('title');

  return (
    <Layout>
      <LayoutHeader>
        <LayoutTitle>
          <RHFGroup control={control} id={uid('title')} name="title" required>
            {({ field }) => <HeaderInput {...field} />}
          </RHFGroup>
        </LayoutTitle>
      </LayoutHeader>
      <LayoutBody>
        <RHFGroup control={control} id={uid('url')} isUrl label="External URL" name="content.link.url" required>
          {({ field }) => <Input {...field} placeholder="https://www.google.com/" />}
        </RHFGroup>
        <RHFGroup control={control} id={uid('external')} name="content.link.new_tab">
          {({ field }) => (
            <Toggle
              checked={!!field.value}
              label="Open in new tab"
              onChange={ev => field.onChange(ev.target.checked)}
              type="toggle"
            />
          )}
        </RHFGroup>
      </LayoutBody>
    </Layout>
  );
}
