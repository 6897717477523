import type { APIDefinitionsReadType } from '@readme/api/src/mappings/apis/types';

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useProjectStore } from '@core/store';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Tooltip from '@ui/Tooltip';

import blockImage from './blocks.svg';
import ApiDefinitionCreateOrUpdate from './CreateOrUpdate';
import ApiDefinitionImporter from './Importer';
import styles from './index.module.scss';

function OpenAPITooltip() {
  const bem = useClassy(styles, 'ApiDefinitionForm');
  return (
    <Tooltip
      arrow={false}
      content={
        <div className={bem('-tooltip-content')}>
          <p>An OpenAPI file defines the structure and behavior of your REST API.</p>
          <p>
            <Flex
              align="center"
              className={bem('-tooltip-content-link')}
              gap="xs"
              href="https://docs.readme.com/main/docs/openapi#supported-file-types"
              justify="start"
              rel="noreferrer"
              tag="a"
              target="_blank"
            >
              Supported File Types{' '}
              <Icon
                aria-label="Opens in a new tab"
                className={bem('-tooltip-content-link-icon')}
                name="arrow-up-right"
              />
            </Flex>
          </p>
        </div>
      }
      delay={[400, 200]}
      interactive
      offset={[0, 5]}
      placement="bottom"
    >
      <span className={bem('-tooltip')}>OpenAPI file</span>
    </Tooltip>
  );
}

export interface ApiDefinitionFormProps {
  /**
   * Indicates whether the form should be catered to a user who is setting up
   * API definitions for the first time vs adding a new one to an existng set.
   * `setup` - Setting up API definitions for the first time (this is the default).
   * `create` - Adding a new API definition for the manual API Designer.
   * `update` - Updating an existing API definition for the manual API Designer.
   * `import` - Adding a new API definition from a file.
   * `replace` - Replacing an existing API definition with a new file.
   */
  action?: 'create' | 'import' | 'replace' | 'setup' | 'update';

  /**
   * Enables the ability to create a new API definition with a manual editor.
   * Defaults to `false`.
   */
  allowManualEditor?: boolean;

  /**
   * The API definition to be used when updating an existing definition.
   */
  definition?: APIDefinitionsReadType | null;

  /**
   * When provided, renders a cancel button that invokes this callback. Use this
   * when the form needs a way to cancel the operation and navigate back to some
   * previous state.
   */
  onCancel?: () => void;
  onCreate?: () => void;
}

const formTitleText = {
  create: 'Add API Definition',
  import: 'Add API Definition',
  replace: 'Replace API Definition',
  setup: 'Set Up API Reference',
  update: 'Edit API Definition',
} satisfies Record<NonNullable<ApiDefinitionFormProps['action']>, string>;

/**
 * Renders a form that can be used either for initial setup or adding additional
 * API definitions.
 */
function ApiDefinitionForm({ action = 'setup', definition, onCancel, onCreate }: ApiDefinitionFormProps) {
  const bem = useClassy(styles, 'ApiDefinitionForm');

  const allowEditing = useProjectStore(s => s.data.api_designer.allow_editing);

  return (
    <Flex align="stretch" className={bem()} gap="sm" justify="center" layout="col">
      <header className={bem('-header')}>
        <Flex align="center" className={bem('-header-title')} justify="center" tag="h3">
          {!!onCancel && (
            <Button
              aria-label="Cancel API definition"
              circular
              className={bem('-cancel-action')}
              kind="minimum"
              onClick={() => onCancel?.()}
              outline
              size="xs"
            >
              <Icon name="arrow-left" />
            </Button>
          )}
          <span>{formTitleText[action]}</span>
        </Flex>

        {action === 'replace' ? (
          <p className={bem('-header-description')}>
            Overwrite the contents of the <OpenAPITooltip /> for <strong>{definition?.filename}</strong>.
          </p>
        ) : action === 'setup' ? (
          <p className={bem('-header-description')}>
            ReadMe generates reference docs from an <OpenAPITooltip />. If you don’t have one, use our API Designer to
            build one now.
          </p>
        ) : null}
      </header>

      {['create', 'update'].includes(action) && (
        <ApiDefinitionCreateOrUpdate
          definition={definition}
          onCancel={() => onCancel?.()}
          onSave={() => onCancel?.()}
        />
      )}

      <Flex align="stretch" className={bem('-body')}>
        {['setup', 'import', 'replace'].includes(action) && (
          <ApiDefinitionImporter
            className={bem('-importer')}
            filename={definition?.filename}
            onImport={() => onCancel?.()}
          />
        )}
        {['setup', 'import'].includes(action) && (
          <>
            <Flex align="center" className={bem('-divider')} justify="center">
              <span className={bem('-divider-or')}>OR</span>
            </Flex>
            <Flex className={bem('-createNew')} layout="col" tag="section">
              <img alt="" height="42" src={blockImage} width="83" />
              <Flex gap="sm" layout="col">
                <h4 className={bem('-createNew-title')}>Build an API definition from scratch</h4>
                <p className={bem('-createNew-description')}>
                  If you don’t already have an API definition, we can walk you through creating one.
                </p>

                {allowEditing === 'disabled' ? (
                  <Flex align="center" className={bem('-createNew-disabled')} gap="xs">
                    <Icon aria-hidden="true" name="info" />
                    Disabled by Admin
                  </Flex>
                ) : (
                  <Button className={bem('-createNew-button')} onClick={() => onCreate?.()} text>
                    Start Building
                    <Icon name="arrow-right" />
                  </Button>
                )}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default ApiDefinitionForm;
