import type { PlanFeatureKey } from '@readme/iso';

import React from 'react';

import useProjectPlan from '@core/hooks/useProjectPlan';
import classy from '@core/utils/classy';

import PlanAccessBadge from '@routes/SuperHub/components/PlanAccess/Badge';
import useSectionFeature from '@routes/SuperHub/components/PlanAccess/useSectionFeature';

import DisabledSectionBadge from '@ui/DisabledSectionBadge';

type Section = NonNullable<Parameters<typeof useSectionFeature>[0]>;

/** Hash map of route sections to project plan features. */
const planFeatureMap = {
  changelog: {
    feature: 'changelogs',
    icon: 'icon-changelog',
  },
  discuss: {
    feature: 'discussions',
    icon: 'icon-discussions',
  },
  docs: {
    feature: 'guides',
    icon: 'icon-guides',
  },
  graphql: {
    feature: undefined,
    icon: 'icon-graphql1',
  },
  home: {
    feature: 'landing_page',
    icon: 'icon-landing-page-2',
  },
  landing: {
    feature: 'landing_page',
    icon: 'icon-landing-page-2',
  },
  page: {
    feature: 'custom_pages',
    icon: 'icon-file',
  },
  recipes: {
    feature: 'recipes',
    icon: 'icon-recipes',
  },
  reference: {
    feature: undefined,
    icon: 'icon-references',
  },
} as const satisfies Record<
  NonNullable<Section>,
  {
    feature?: PlanFeatureKey;
    icon: string;
  }
>;

interface SectionGateBadgeProps {
  className?: string;
  isEnabled: boolean;
  section: Section | 'custompage';
}

/**
 * Renders the appropriate icon for the provided route section (e.g. `docs`,
 * `reference`, `discuss`) based on several factors. If section is being trialed
 * or is restricted from being accessed, a plan access badge is rendered. If
 * section is disabled, a "hidden" icon is rendered. Otherwise, the icon
 * associated to the section is rendered to indicate full access.
 */
export function SectionGateBadge({ className, isEnabled, section }: SectionGateBadgeProps) {
  const { showTrialIndicator } = useProjectPlan();

  // The Hub NavItem that consumes this component in view mode uses 'custompage'
  // instead of 'page' for the section prop.
  const normalizedSection = section === 'custompage' ? 'page' : section;

  const isSectionAllowed = useSectionFeature(normalizedSection);

  if (!normalizedSection || !(normalizedSection in planFeatureMap)) return null;
  const { feature, icon } = planFeatureMap[normalizedSection];

  return showTrialIndicator(feature) ? (
    <PlanAccessBadge access="trial" className={className} iconOnly plan="business" />
  ) : !isSectionAllowed ? (
    <PlanAccessBadge access="locked" className={className} iconOnly plan="business" />
  ) : isEnabled ? (
    // Use the legacy icon font here because customers have historically targeted
    // the icon font classnames in their custom CSS.
    <i className={classy(icon, className)} />
  ) : (
    <DisabledSectionBadge className={className} />
  );
}
