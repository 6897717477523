import React from 'react';

import useRdmdOpts from '@core/hooks/useRdmdOpts';
import classy from '@core/utils/classy';

import RDMD from '@ui/RDMD';

import styles from '../style.module.scss';

export interface TextBlockProps {
  className?: string;
  text: string;
  title: string;
}

function TextBlock({ title, text, className }: TextBlockProps) {
  const rdmdOpts = useRdmdOpts();

  return (
    <div className={classy(styles.LandingBlock, styles['LandingBlock-Text'], className)}>
      <RDMD opts={rdmdOpts}>{[title ? `## ${title}` : '', text].join('\n\n')}</RDMD>
    </div>
  );
}

export default TextBlock;
