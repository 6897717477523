import type { APIDefinitionsReadType } from '@readme/api/src/mappings/apis/types';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import classes from './index.module.scss';

const typeMap: Record<APIDefinitionsReadType['type'], string> = {
  openapi: 'OpenAPI Spec',
  postman: 'Postman Collection',
  swagger: 'Swagger Spec',
  unknown: 'Unknown Spec',
} as const;

/**
 * Renders metadata about an API definition, including the type and source of
 * the imported OAS.
 */
const ApiDefinitionType: React.FC<Pick<APIDefinitionsReadType, 'type'>> = ({ type }) => {
  const bem = useClassy(classes, 'ApiDefinitionType');
  let iconName;

  switch (type) {
    case 'openapi':
    case 'swagger':
      iconName = 'oas';
      break;
    case 'postman':
      iconName = 'rocket';
      break;
    default:
      iconName = 'help-circle';
  }

  return (
    <Flex align="center" className={bem('&')} gap="3px" justify="start">
      <Icon aria-hidden="true" color="color-text-minimum" name={iconName} size="sm" />
      <span className={bem('-title')}>{typeMap[type]}</span>
    </Flex>
  );
};

export default ApiDefinitionType;
