import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import React, { createContext, useContext, useState } from 'react';

interface SidebarNavContextValue {
  ephemeralCategory: Partial<GitSidebarCategory> | undefined;
  setEphemeralCategory: React.Dispatch<React.SetStateAction<SidebarNavContextValue['ephemeralCategory']>>;
}

const SidebarNavContext = createContext<SidebarNavContextValue | null>(null);
SidebarNavContext.displayName = 'SidebarNavContext';

/**
 * Provides state properties that is required by our sidebar virtual tree.
 */
export function SidebarNavContextProvider({ children }: { children: React.ReactNode }) {
  const [ephemeralCategory, setEphemeralCategory] = useState<SidebarNavContextValue['ephemeralCategory']>();

  return (
    <SidebarNavContext.Provider
      value={{
        ephemeralCategory,
        setEphemeralCategory,
      }}
    >
      {children}
    </SidebarNavContext.Provider>
  );
}

export function useSidebarNavContext() {
  const value = useContext(SidebarNavContext);
  if (!value) throw new Error('SidebarNavContextProvider is missing.');
  return value;
}
