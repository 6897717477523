/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SupportedLanguages } from '@readme/oas-to-snippet/languages';

import { getStatusCodeMessage, codes } from '@readme/http-status-codes';
import { uppercase } from '@readme/syntax-highlighter';
import React, { useMemo, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useAPIDesignerStore } from '@core/store';

import type { CompiledResponseExamples } from '@ui/APIDesigner/ResponseExampleEditor';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu, { MenuHeader, MenuItem } from '@ui/Menu';

import classes from './style.module.scss';

interface APIDesignerTabProps {
  example:
    | {
        index: number;
        languages: SupportedLanguages;
        sample: any;
        selectedExampleType: 'request';
      }
    | {
        key: string;
        mediaType: string;
        namedId: string | undefined;
        responseExamples: CompiledResponseExamples;
        selectedExampleType: 'response';
        statusCode: string;
      }
    | {
        selectedExampleType: 'callback';
      };
  theme?: 'dark' | 'light';
}

const getCodeType = (code: string) => {
  if (code) {
    if (code.startsWith('1') || code.startsWith('2')) {
      return 'success';
    }
    if (code.startsWith('3') || code.startsWith('4')) {
      return 'warning';
    }
    if (code.startsWith('5')) {
      return 'error';
    }
  }
  return 'success';
};

const APIDesignerTab = ({ example, theme = 'dark' }: APIDesignerTabProps) => {
  const bem = useClassy(classes, 'APIDesignerTab');
  const [pendingName, setPendingName] = useState<string | null>(null);

  const [
    codeSamples,
    deleteRequestExample,
    deleteResponseExample,
    selectedRequestExample,
    selectedResponseExample,
    setLanguage,
    setSelectedRequestExample,
    setSelectedResponseExample,
    updateExampleName,
    updateResponseExampleName,
    updateStatusCode,
  ] = useAPIDesignerStore(s => [
    s.requestExampleEditor.codeSamples,
    s.requestExampleEditor.deleteRequestExample,
    s.responseExampleEditor.deleteResponseExample,
    s.requestExampleEditor.selectedExample,
    s.responseExampleEditor.selectedExample,
    s.requestExampleEditor.setLanguage,
    s.requestExampleEditor.setSelectedExample,
    s.responseExampleEditor.setSelectedExample,
    s.requestExampleEditor.updateExampleName,
    s.responseExampleEditor.updateResponseExampleName,
    s.responseExampleEditor.updateStatusCode,
  ]);

  const [selectedCodeCategory, setSelectedCodeCategory] = useState<ReturnType<typeof getCodeType>>(
    (example.selectedExampleType === 'response' && getCodeType(example.statusCode)) || 'success',
  );

  const splitCodes = useMemo(() => {
    if (example.selectedExampleType !== 'response') return {};

    // split codes into three groups, success, warning and error using getCodeType function
    return Object.keys(codes).reduce(
      (acc, code) => {
        const type = getCodeType(code);
        acc[type].push(code);
        return acc;
      },
      { success: [] as string[], warning: [] as string[], error: [] as string[] },
    );
  }, [example.selectedExampleType]);

  const displayedRequestTabName = useMemo(() => {
    if (example.selectedExampleType !== 'request') return '';

    // If temporary name is cleared, show the default language name since this is what will be set
    if (pendingName === '') {
      return uppercase(example.sample.language);
    }

    return `${uppercase(pendingName || example.sample.name || example.sample.language)}`;
  }, [example, pendingName]);

  return (
    <>
      {example.selectedExampleType === 'request' && (
        <Dropdown justify="start" trigger={selectedRequestExample === example.index ? 'click' : ''}>
          <Flex
            align="center"
            className={bem('&', theme === 'light' && '_light', selectedRequestExample === example.index && '_active')}
            gap="4px"
            onClick={() => setSelectedRequestExample(example.index)}
            tag="button"
            type="button"
          >
            {displayedRequestTabName}
            {selectedRequestExample === example.index ? <Icon color="gray70" name="chevron-down" size={14} /> : ''}
          </Flex>
          <Menu className={bem('-menu')} theme={theme}>
            <MenuItem data-color-mode={theme === 'dark' ? 'dark' : ''} focusable={false} TagName="div">
              <Input
                onChange={e => {
                  setPendingName(e.target.value);
                  updateExampleName(e.target.value);
                }}
                placeholder="Example Name"
                size="xs"
                value={example.sample.name}
              />
            </MenuItem>
            <MenuHeader>Syntax Highlighting</MenuHeader>
            <div className={bem('-menu-languages')}>
              {Object.keys(example.languages!).map((language, i) => (
                <MenuItem
                  key={i}
                  active={codeSamples![selectedRequestExample]?.language === language}
                  onClick={() => setLanguage(language)}
                >
                  {uppercase(language)}
                </MenuItem>
              ))}
            </div>
            <footer className={bem('-menu-footer')}>
              <MenuItem color="red" onClick={deleteRequestExample}>
                <Icon name="trash" />
                Delete
              </MenuItem>
            </footer>
          </Menu>
        </Dropdown>
      )}
      {example.selectedExampleType === 'response' && (
        <Dropdown
          justify="start"
          onToggle={value => {
            let newName = pendingName;

            // If the user is deleting the name, set it to a default one
            if (typeof pendingName === 'string' && pendingName === '') {
              newName = 'New Example';
            }

            // When the dropdown is closed, update the example name
            if (!value && pendingName !== null && newName !== null) {
              updateResponseExampleName(newName);
            }
          }}
          sticky
          trigger={
            example.statusCode === selectedResponseExample?.status &&
            example.namedId === selectedResponseExample?.namedId
              ? 'click'
              : ''
          }
        >
          <Flex
            align="center"
            className={bem(
              '&',
              theme === 'light' && '_light',
              example.statusCode === selectedResponseExample?.status &&
                example.namedId === selectedResponseExample?.namedId &&
                '_active',
            )}
            gap="4px"
            onClick={() =>
              setSelectedResponseExample({
                mediaType: example.mediaType,
                status: example.statusCode,
                id: example.key,
                namedId: example.namedId,
              })
            }
            tag="button"
            type="button"
          >
            <HTTPStatus iconOnly={true} status={example.statusCode} />
            {example.statusCode} - {pendingName || example.namedId}
            {example.statusCode === selectedResponseExample?.status &&
            example.namedId === selectedResponseExample?.namedId ? (
              <Icon color="gray70" name="chevron-down" size={14} />
            ) : (
              ''
            )}
          </Flex>
          <Menu className={bem('-menu')} theme={theme}>
            <MenuItem data-color-mode={theme === 'dark' ? 'dark' : ''} focusable={false} TagName="div">
              <Input
                onChange={e => {
                  setPendingName(e.target.value);
                }}
                placeholder="Example Name"
                size="xs"
                value={selectedResponseExample?.namedId}
              />
            </MenuItem>
            <MenuHeader>
              <Button
                ghost={selectedCodeCategory !== 'success'}
                kind={selectedCodeCategory === 'success' ? 'primary' : 'secondary'}
                onClick={() => setSelectedCodeCategory('success')}
                size="xs"
              >
                {selectedCodeCategory === 'success' ? <HTTPStatus iconOnly status={200} /> : null}
                Success
              </Button>
              <Button
                ghost={selectedCodeCategory !== 'warning'}
                kind={selectedCodeCategory === 'warning' ? 'primary' : 'secondary'}
                onClick={() => setSelectedCodeCategory('warning')}
                size="xs"
              >
                {selectedCodeCategory === 'warning' ? <HTTPStatus iconOnly status={300} /> : null}
                Warning
              </Button>
              <Button
                ghost={selectedCodeCategory !== 'error'}
                kind={selectedCodeCategory === 'error' ? 'primary' : 'secondary'}
                onClick={() => setSelectedCodeCategory('error')}
                size="xs"
              >
                {selectedCodeCategory === 'error' ? <HTTPStatus iconOnly status={500} /> : null}
                Error
              </Button>
            </MenuHeader>
            <div className={bem('-menu-languages')}>
              {!!selectedCodeCategory &&
                splitCodes[selectedCodeCategory].map((code, i) => (
                  <MenuItem
                    key={i}
                    active={selectedResponseExample?.status === code}
                    onClick={() => updateStatusCode(code)}
                  >
                    {getStatusCodeMessage(code)}
                  </MenuItem>
                ))}
            </div>
            <footer className={bem('-menu-footer')}>
              <MenuItem color="red" onClick={() => deleteResponseExample()}>
                <Icon name="trash" />
                Delete
              </MenuItem>
            </footer>
          </Menu>
        </Dropdown>
      )}
    </>
  );
};

export default APIDesignerTab;
