import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Alert from '@ui/Alert';
import Button from '@ui/Button';
import Icon from '@ui/Icon';

import classes from './style.module.scss';

const Subtitle = ({ editUrl }: { editUrl: string }) => {
  const bem = useClassy(classes, 'MigrationError');

  return (
    <>
      Your docs displays the pre-migration version of this page until errors are corrected.
      <div className={bem('-help')}>
        <Button
          className={bem('-help-link')}
          href={editUrl}
          kind="destructive"
          link
          rel="noreferrer"
          size="xs"
          target="_blank"
        >
          Fix Page <Icon name="arrow-right" size="md" />
        </Button>
      </div>
    </>
  );
};

const MigrationError = ({ editUrl }: { editUrl: string }) => {
  const title = 'Page Contains Invalid MDX';

  return <Alert subtitle={<Subtitle editUrl={editUrl} />} title={title} type="danger" />;
};

export default React.memo(MigrationError);
