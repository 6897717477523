import React from 'react';
import { useWatch } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import { RHFGroup } from '@ui/RHF';
import Timestamp from '@ui/Timestamp';

import HeaderInput from '../components/HeaderInput';
import MarkdownEditor from '../components/MarkdownEditor';
import { useSuperHubEditorFormContext } from '../Context';
import Layout, { LayoutBody, LayoutHeader, LayoutTitle } from '../Layout';
import useFormSetFocus from '../useFormSetFocus';

import styles from './index.module.scss';

/**
 * Form fields specific to custom pages.
 */
export default function Changelog() {
  const bem = useClassy(styles, 'Changelog');
  const uid = useUniqueId('Changelog');
  const { control } = useSuperHubEditorFormContext();
  const [authorName, createdAt] = useWatch({
    control,
    name: ['author.name', 'created_at'],
  });

  useFormSetFocus('title');

  return (
    <Layout>
      <LayoutHeader>
        <LayoutTitle>
          <RHFGroup control={control} id={uid('title')} name="title" required>
            {({ field }) => <HeaderInput {...field} />}
          </RHFGroup>
        </LayoutTitle>

        <h4 className={bem('-author')}>
          By {authorName}
          {!!createdAt && (
            <>
              {' '}
              • <Timestamp value={createdAt} />
            </>
          )}
        </h4>
      </LayoutHeader>
      <LayoutBody>
        <MarkdownEditor />
      </LayoutBody>
    </Layout>
  );
}
