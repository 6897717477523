import type { OpenAPIV3 } from 'openapi-types';

import React from 'react';

import Icon from '@ui/Icon';
import Menu, { MenuDivider, MenuHeader, MenuItem } from '@ui/Menu';

const SCHEMA_TYPES = ['array', 'boolean', 'integer', 'number', 'object', 'string'] as const;

export type SchemaType = (typeof SCHEMA_TYPES)[number];
export type SchemaFormat =
  | 'binary'
  | 'byte'
  | 'date-time'
  | 'date'
  | 'double'
  | 'float'
  | 'int32'
  | 'int64'
  | 'password';

const basicTypes: { icon: string; type: SchemaType }[] = [
  {
    icon: 'string',
    type: 'string',
  },
  {
    icon: 'integer',
    type: 'integer',
  },
  {
    icon: 'binary',
    type: 'boolean',
  },
];

const numberTypes: { icon: string; type: SchemaType; format: SchemaFormat }[] = [
  {
    icon: 'life-buoy',
    type: 'number',
    format: 'float',
  },
  {
    icon: 'double',
    type: 'number',
    format: 'double',
  },
  {
    icon: 'integer',
    type: 'integer',
    format: 'int32',
  },
  {
    icon: 'integer',
    type: 'integer',
    format: 'int64',
  },
];

const stringTypes: { icon: string; type: SchemaType; format: SchemaFormat }[] = [
  {
    icon: 'calendar',
    type: 'string',
    format: 'date',
  },
  {
    icon: 'clock',
    type: 'string',
    format: 'date-time',
  },
  {
    icon: 'lock',
    type: 'string',
    format: 'password',
  },
  {
    icon: 'string',
    type: 'string',
    format: 'byte',
  },
  {
    icon: 'binary',
    type: 'string',
    format: 'binary',
  },
];

const allTypes: { type: SchemaType; format?: SchemaFormat }[] = [...basicTypes, ...numberTypes, ...stringTypes];
/**
 * A map of schema type -> valid formats for that type. For example:
 *
 * ```
 * {
 *   integer: ['int32', 'int64'],
 *   number: ['float', 'double'],
 *   // ...etc
 * }
 * ```
 */
const TYPE_TO_VALID_FORMATS = allTypes.reduce<Record<SchemaType, SchemaFormat[]>>(
  (acc, curr) => {
    if (curr.format) {
      acc[curr.type].push(curr.format);
    }
    return acc;
  },
  { string: [], number: [], integer: [], boolean: [], object: [], array: [] },
);

interface TypeMenuProps {
  format?: SchemaFormat;
  items?: OpenAPIV3.ArraySchemaObject['items'] & OpenAPIV3.SchemaObject;
  setNewType: ({
    format,
    items,
    type,
  }: {
    format?: SchemaFormat;
    // Left this type simple since getting it with the OpenAPI types is a bit complex
    items?: { format?: SchemaFormat; type: SchemaType };
    type: SchemaType;
  }) => void;
  shouldShowAllowObject: boolean;
  type: SchemaType;
}

/**
 * Sets the correct type/format for the selected type. For simplicity we
 * combined them into one menu as opposed to making it two different things that
 * user sets.
 */
const TypeMenu = ({ setNewType, type, format, items, shouldShowAllowObject }: TypeMenuProps) => {
  return (
    <Menu>
      {basicTypes.map(t => (
        <MenuItem
          key={t.type}
          active={type === t.type && !format}
          icon={<Icon name={t.icon || 'blank'} />}
          onClick={() => setNewType({ type: t.type })}
        >
          {t.type}
        </MenuItem>
      ))}
      <MenuItem
        active={type === 'array' && !format}
        icon={<Icon name="brackets" />}
        onClick={() => setNewType({ type: 'array' })}
      >
        <span>array</span>
        <Menu>
          {basicTypes.map(t => (
            <MenuItem
              key={t.type}
              active={type === 'array' && !!items && items.type === t.type && !items.format}
              icon={<Icon name={t.icon || 'blank'} />}
              onClick={() => setNewType({ type: 'array', items: { type: t.type } })}
            >
              {t.type}
            </MenuItem>
          ))}
          <MenuDivider />
          <MenuHeader>Number Format</MenuHeader>
          {numberTypes.map(t => (
            <MenuItem
              key={t.format}
              active={type === 'array' && !!items && items?.type === t.type && items?.format === t.format}
              icon={<Icon name={t.icon || 'blank'} />}
              onClick={() =>
                setNewType({
                  type: 'array',
                  items: { type: t.type, format: t.format },
                })
              }
            >
              {t.format}
            </MenuItem>
          ))}
          <MenuDivider />
          <MenuHeader>String Format</MenuHeader>
          {stringTypes.map(t => (
            <MenuItem
              key={t.format}
              active={type === 'array' && !!items && items?.type === t.type && items?.format === t.format}
              icon={<Icon name={t.icon || 'blank'} />}
              onClick={() =>
                setNewType({
                  type: 'array',
                  items: { type: t.type, format: t.format },
                })
              }
            >
              {t.format}
            </MenuItem>
          ))}
        </Menu>
      </MenuItem>
      {!!shouldShowAllowObject && (
        <MenuItem
          active={type === 'object' && !format}
          icon={<Icon name="json" />}
          onClick={() => setNewType({ type: 'object' })}
        >
          object
        </MenuItem>
      )}
      <MenuDivider />
      <MenuHeader>Number Format</MenuHeader>
      {numberTypes.map(t => (
        <MenuItem
          key={t.format}
          active={type === t.type && format === t.format}
          icon={<Icon name={t.icon || 'blank'} />}
          onClick={() => setNewType({ type: t.type, format: t.format })}
        >
          {t.format}
        </MenuItem>
      ))}
      <MenuDivider />
      <MenuHeader>String Format</MenuHeader>
      {stringTypes.map(t => (
        <MenuItem
          key={t.format}
          active={type === t.type && format === t.format}
          icon={<Icon name={t.icon || 'blank'} />}
          onClick={() => setNewType({ type: t.type, format: t.format })}
        >
          {t.format}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default TypeMenu;
export { default as TypeMenuButton } from './Button';

export function validatedSchemaType(schema: {
  type: SchemaType;
  format?: SchemaFormat;
  items?: OpenAPIV3.SchemaObject;
}) {
  // extract the type and format either from the top-level or the schema's
  // `items` in the case of an array
  const items = schema.items || { type: undefined, format: undefined };
  const { type, format } = schema.type === 'array' ? items : schema;

  // preserve the provided type if it's a valid value, otherwise display "unknown"
  const typeLabel = type && SCHEMA_TYPES.includes(type) ? type : 'unknown';
  // preserve the provided format if it's undefined or a valid value for the
  // provided type, otherwise display "unknown"
  const formatLabel =
    !format || (type && TYPE_TO_VALID_FORMATS[type].includes(format as SchemaFormat)) ? format : 'unknown';

  let label = '';
  if (schema.type === 'array') {
    label = `array (${formatLabel || typeLabel})`;
  } else {
    label = formatLabel ? `${typeLabel} (${formatLabel})` : typeLabel;
  }

  return {
    /**
     * The display label to use for the provided schema's type and format. This
     * includes replacing any invalid values with "unknown" (e.g. "array
     * (unknown)").
     */
    label,
    /**
     * Either the schema's type or its `items` type when an array. This returns
     * the provided value as-is without validation.
     */
    type,
    /**
     * The label of either the schema's type or its `items` type when an array.
     * This will be "unknown" if the provided type is invalid.
     */
    typeLabel,
    /**
     * Either the schema's format or its `items` format when an array. This returns
     * the provided value as-is without validation.
     */
    format,
    /**
     * The label of either the schema's format or its `items` format when an
     * array. This will be "unknown" if the provided format is invalid.
     */
    formatLabel,
  };
}
