import produce from 'immer';
import React from 'react';
import { useWatch } from 'react-hook-form';

import type { SuperHubGuideReferencePage } from '@core/store';
import { useSuperHubStore } from '@core/store';
import { isReferencePage } from '@core/store/SuperHub/Document/util';

import Icon from '@ui/Icon';
import Menu, { MenuItem } from '@ui/Menu';
import Tooltip from '@ui/Tooltip';

import { useSuperHubEditorFormContext } from '../../Context';

interface EndpointDefinitionMenuItemProps {
  /**
   * The currently selected page type.
   */
  currentSelected: string;
  onChange: (value: SuperHubGuideReferencePage['type']) => void;
}

function EndpointDefinitionMenuItem({ currentSelected, onChange }: EndpointDefinitionMenuItemProps) {
  const [apiDefinitions, getDefinition] = useSuperHubStore(s => [
    s.apiDefinitions.data,
    s.apiDefinitions.getDefinition,
  ]);

  const hasDefinitions = !!apiDefinitions?.length;

  const {
    control,
    reset,
    formState: { defaultValues },
  } = useSuperHubEditorFormContext();
  const apiDefinition = useWatch({ control, name: 'api' });

  const changeSelectedApiDefinition = async (selectedApiDefinition: NonNullable<typeof apiDefinitions>[number]) => {
    if (!isReferencePage(defaultValues)) return;
    const { data: definition } = await getDefinition(selectedApiDefinition.filename);

    // API Designer requires updating the form's default values with the new
    // schema in order to re-initialize the API Designer store.
    reset(
      produce(defaultValues, draft => {
        draft.api.schema = definition.schema;
        draft.api.uri = definition.uri;
      }),
      // Preserve existing changes made to other form fields like title, etc.
      { keepDirtyValues: true },
    );

    // Wire up the change handler to notify RHFGroup of the updated type. This
    // must be done in a separate step *after* the reset.
    onChange('endpoint');
  };

  const filename = apiDefinition?.uri?.split('/').pop();

  if (!hasDefinitions) {
    return (
      <MenuItem disabled icon={<Icon name="share-2" />} role="menuitem">
        <Tooltip asTitle content="Create an API definition to add endpoints">
          <span>API Endpoint</span>
        </Tooltip>
      </MenuItem>
    );
  }

  // We can't support changing the API definition after the endpoint already has been set to one
  // This would require removing it from one file and adding it to the newly selected one
  if (currentSelected === 'endpoint') {
    return (
      <MenuItem active description={filename || ''} icon={<Icon name="share-2" />} role="menuitem">
        API Endpoint
      </MenuItem>
    );
  }

  return (
    <MenuItem icon={<Icon name="share-2" />} role="menuitem">
      <span>API Endpoint</span>
      {!!hasDefinitions && (
        <Menu>
          {apiDefinitions.map(ad => (
            <MenuItem key={ad.filename} onClick={() => changeSelectedApiDefinition(ad)} role="menuitem">
              {ad.filename}
            </MenuItem>
          ))}
        </Menu>
      )}
    </MenuItem>
  );
}

export default EndpointDefinitionMenuItem;
