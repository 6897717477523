import type { TreeNodeNewCategory } from './types';

import React, { useCallback } from 'react';

import { useSuperHubStore } from '@core/store';

import type { PageNavCategoryProps } from '@ui/Dash/PageNav';
import { PageNavCategory } from '@ui/Dash/PageNav';

import { useSidebarNavContext } from './Context';

interface SidebarNavNewCategoryProps {
  node: TreeNodeNewCategory;
}

export function SidebarNavNewCategory({ node }: SidebarNavNewCategoryProps) {
  const { setEphemeralCategory } = useSidebarNavContext();

  const [createCategory, findSidebarCategoryByTitle] = useSuperHubStore(s => [
    s.sidebar.createCategory,
    s.sidebar.findSidebarCategoryByTitle,
  ]);

  const handleCategoryEdit = useCallback<NonNullable<PageNavCategoryProps['onEdit']>>(
    ({ label: newTitle, isValid }) => {
      // Submit edits only if the edits made are "valid".
      if (!isValid) {
        setEphemeralCategory(undefined);
        return undefined;
      }

      // Validate category name to prevent duplicate titles.
      const isDuplicateTitle = !!findSidebarCategoryByTitle(newTitle);
      if (isDuplicateTitle) {
        return 'Category title is already taken.';
      }

      setEphemeralCategory(undefined);
      createCategory(newTitle);
      return undefined;
    },
    [createCategory, findSidebarCategoryByTitle, setEphemeralCategory],
  );

  return <PageNavCategory editable label={node.label} onEdit={handleCategoryEdit} showActions={false} />;
}
