import type { TreeNodeNewPage } from './types';

import React from 'react';

import { PageNavItem } from '@ui/Dash/PageNav';

export interface SidebarNavNewPageProps {
  node: TreeNodeNewPage;
}

export const SidebarNavNewPage = React.memo(function SidebarNavNewPage({ node }: SidebarNavNewPageProps) {
  return (
    <PageNavItem
      active
      isPrivate
      label={node.label}
      level={node.nestingLevel}
      showActions={false}
      type={node.pageType}
    />
  );
});
