import type { ReadChangelogCollectionType } from '@readme/api/src/mappings/changelog/types';
import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { DiscussDocument } from '@readme/backend/models/discuss/types';
import type { HubResponseProps } from '@readme/iso';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import SectionGate from '@routes/SuperHub/components/PlanAccess/SectionGate';

import styles from './index.module.scss';
import LandingPage from './LandingPage';

export interface HomeRouteProps {
  changelogs: ReadChangelogCollectionType['data'];
  discuss: DiscussDocument[];
  guideCategories: GitSidebarCategory[];
  referenceCategories: GitSidebarCategory[];
}

export default function SuperHubHome(props: HubResponseProps<HomeRouteProps>) {
  const bem = useClassy(styles, 'SuperHubHome');

  return (
    <SectionGate contentClassName={bem('-gate-content')} section="home">
      <LandingPage {...props} />
    </SectionGate>
  );
}
