import type { APIDefinitionsReadType } from '@readme/api/src/mappings/apis/types';

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import styles from './index.module.scss';
import ApiDefinitionItem from './Item';
import APIDesignerSettingsDropdown from './SettingsDropdown';

interface ApiDefinitionListProps {
  className?: string;

  /**
   * Invoked when users click to "add" a new definition.
   */
  onCreate?: () => void;

  onEdit?: (definition: APIDefinitionsReadType) => void;

  /**
   * Invoked when users click to "replace" an existing definition.
   */
  onReplace?: (definition: APIDefinitionsReadType) => void;
}

/**
 * Renders a list of API definitions that have been previously added. Allows
 * users to manage existing API definitions to update or delete them.
 */
function ApiDefinitionList({ className, onCreate, onReplace, onEdit }: ApiDefinitionListProps) {
  const bem = useClassy(styles, 'ApiDefinitionList');
  const [apiDefinitions] = useSuperHubStore(s => [s.apiDefinitions.data]);

  return (
    <Flex align="stretch" className={bem('&', className)} gap="md" justify="center" layout="col">
      <Flex align="center" tag="header" wrap="wrap">
        <h1 className={bem('-title')}>API Definitions</h1>
        <Flex className={bem('-actions')} gap="sm">
          <Button onClick={() => onCreate?.()} size="sm">
            <Icon aria-hidden="true" name="plus" />
            Add
          </Button>
          <APIDesignerSettingsDropdown />
        </Flex>
      </Flex>

      <Flex align="stretch" className={bem('-list')} gap="sm" layout="col" tag="ul">
        {apiDefinitions?.map(definition => (
          <ApiDefinitionItem key={definition.uri} definition={definition} onEdit={onEdit} onReplace={onReplace} />
        ))}
      </Flex>
    </Flex>
  );
}

export default ApiDefinitionList;
