import loadable from '@loadable/component';
import React, { useContext, useEffect } from 'react';

import { AppMetaContext, ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';

import logoDefault from './logo.svg';
import classes from './style.module.scss';

const CustomPage = loadable(() => import('@routes/CustomPage'), { ssr: true });
const SuperHubCustomPage = loadable(() => import('@routes/SuperHub/CustomPage'), { ssr: true });

/**
 * Renders 404 page not found content.
 * NOTE: Cannot convert this file to TS because `loadable()` is not yet
 * supported in our TS pipeline because it does not load babel.
 */
export default function PageNotFound(props) {
  const { project } = useContext(ProjectContext);
  const { updateAppMeta, ...AppMeta } = useContext(AppMetaContext);
  const { error404, appearance } = project;
  const [logo] = appearance?.logo || [logoDefault];
  const bem = useClassy(classes, 'PageNotFound');

  useEffect(() => {
    if (!error404) {
      updateAppMeta({ ...AppMeta, title: '404 Not Found', type: '404' });
    }
  }, [error404, updateAppMeta]); // eslint-disable-line react-hooks/exhaustive-deps

  if (project.flags.superHub) {
    // For superhub, we only want to render the custom page if the 404 error
    // page does exist and is included in the SSR props. Otherwise, we want to
    // fallback to the generic 404 page.
    if (error404 && 'document' in props) {
      return <SuperHubCustomPage {...props} />;
    }
  } else if (error404) {
    return <CustomPage {...props} />;
  }

  return (
    <div className={bem('&', 'rm-Container')}>
      <img alt="" className={bem('-logo')} src={logo} />
      <h2 className={bem('-title')}>Page Not Found</h2>
    </div>
  );
}
