import type { FieldError } from 'react-hook-form';

import React, { useEffect } from 'react';

import { useProjectStore } from '@core/store';

import PageError from '../components/PageError';
import { useSuperHubEditorFormContext } from '../Context';

export default function ServerValidationError() {
  const errorDisplayRef = React.useRef<HTMLDivElement>(null);
  const {
    formState: { errors },
  } = useSuperHubEditorFormContext();
  const [projectName, projectId] = useProjectStore(s => [s.data.name, s.data.id]);

  const error = 'schema' in errors && (errors?.schema as FieldError);
  // We set mark servervalidation errors in react-hook-form state with `type: 'manual'`.
  const validationError = error && error.type === 'manual' ? error.message : null;

  useEffect(() => {
    if (validationError) {
      errorDisplayRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [validationError]);

  return validationError ? (
    <PageError
      ref={errorDisplayRef}
      mailtoAddress="support@readme.com"
      mailtoSubject="API Designer Validation Error"
      message={validationError}
      projectId={projectId}
      projectName={projectName}
      subtitle="We found an error when attempting to save your document. Please correct the following issue and try again:"
      title="We found an error when attempting to save your document"
    />
  ) : null;
}
