import React, { useEffect } from 'react';

import { useSuperHubEditorFormContext } from '@routes/SuperHub/Editor/Form/Context';

import { useAPIDesignerStore } from '.';

/**
 * Connects the API Designer form state to the SuperHub editor form state.
 */
export function ConnectFormToAPIDesignerStore({ children }: { children: React.ReactNode }) {
  const api = useAPIDesignerStore(s => s.apiObject);
  const { setValue } = useSuperHubEditorFormContext();

  useEffect(() => {
    if (api) setValue('api', api, { shouldDirty: true });
  }, [api, setValue]);

  return <>{children}</>;
}
