import type { PayGateProps } from '../PayGate';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import PayGate from '@routes/SuperHub/components/PlanAccess/PayGate';
import useSectionFeature from '@routes/SuperHub/components/PlanAccess/useSectionFeature';
import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import styles from './index.module.scss';

interface SectionGateProps {
  children: React.ReactNode;
  section: SuperHubRouteParams['section'];
}

/** Hash map of route sections to `PayGate` features. */
const payGateFeatureMap = {
  changelog: 'changelog',
  discuss: 'discussions',
  docs: 'guides',
  home: 'home',
  page: 'custom-pages',
  recipes: 'recipes',
  reference: undefined,
} as const satisfies Record<NonNullable<SectionGateProps['section']>, PayGateProps['feature'] | undefined>;

/**
 * Conditionally renders a `PayGate` container for the provided route section
 * based on whether that section is allowed by the current project plan.
 */
export default function SectionGate({
  children,
  className,
  contentClassName,
  section,
}: Pick<PayGateProps, 'className' | 'contentClassName'> & SectionGateProps) {
  const bem = useClassy(styles, 'SectionGate');
  const isSectionAllowed = useSectionFeature(section);
  const feature = section ? payGateFeatureMap[section] : undefined;

  if (!feature) {
    return <>{children}</>;
  }

  return (
    <PayGate
      access={isSectionAllowed ? 'open' : 'locked'}
      className={bem('&', className)}
      contentClassName={bem('-content', contentClassName)}
      feature={feature}
    >
      {children}
    </PayGate>
  );
}

export * from './Badge';
