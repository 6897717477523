import React from 'react';
import { useHistory } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';

import EmptyState from '@routes/SuperHub/components/EmptyState';

/**
 * Default category title to be automatically created in the case where the user
 * deletes all their categories and gets redirected to the create page route.
 */
const defaultCategory = 'Documentation';

/**
 * Renders the empty state for the Guides section when no pages have yet been
 * created.
 */
export default function EmptyGuide() {
  const history = useHistory();
  const [categories, createCategory, isSuperHubAdmin] = useSuperHubStore(s => [
    s.sidebar.data,
    s.sidebar.createCategory,
    s.isSuperHubAdmin,
  ]);

  const targetCategory = categories.length ? categories[0].title : defaultCategory;
  const actionRoute = `/create/docs/${targetCategory}`;

  return isSuperHubAdmin ? (
    <EmptyState
      action={actionRoute}
      onAction={async e => {
        e.preventDefault();
        if (!categories.length) {
          await createCategory(targetCategory);
        }
        history.push(actionRoute);
      }}
      section="guide"
    />
  ) : (
    <EmptyState section="guide" />
  );
}
