import React from 'react';

import { useSuperHubStore } from '@core/store';

import PageNav from '@ui/Dash/PageNav';

import { SidebarNavFooter } from './Footer';

export interface SidebarNavRootProps {
  children?: React.ReactNode;
  className?: string;
}

export const SidebarNavRoot = React.memo(function SidebarNavRoot({ className, children }: SidebarNavRootProps) {
  const [categories, routeSection] = useSuperHubStore(s => [s.sidebar.data, s.routeSection]);

  return (
    <PageNav
      categories={categories.map(c => ({
        _id: c.uri,
        title: c.title,
      }))}
      className={className}
      footer={<SidebarNavFooter />}
      type={routeSection === 'reference' ? 'reference' : 'guide'}
    >
      {children}
    </PageNav>
  );
});
